@font-face {
  font-family: larken;
  src: url("../font-larken/LarkenDEMO-Black.otf");
  src: url("../font-larken/LarkenDEMO-Bold.otf");
  src: url("../font-larken/LarkenDEMO-ExtraBold.otf");
  src: url("../font-larken/LarkenDEMO-Light.otf");
  src: url("../font-larken/LarkenDEMO-Medium.otf");
  src: url("../font-larken/LarkenDEMO-Regular.otf");
  src: url("../font-larken/LarkenDEMO-Thin.otf");
}

/* ============================================================================================== */
body {
  font-family: var(--montserrat);
  background-color: #fff;
  overflow-x: hidden;
}

main,
header,
footer {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: var(--brown);
}

p,
li {
  color: #979797;
}

.brown {
  color: var(--brown) !important;
}

.brown-bg {
  background-color: var(--brown) !important;
}

.green {
  color: var(--green) !important;
}

.green-bg {
  background-color: var(--green) !important;
}

.bg {
  background-color: var(--bg) !important;
}

.bg2 {
  background-color: var(--bg2) !important;
}

.font-1 {
  font-family: var(--montserrat) !important;
}

.font-2 {
  font-family: var(--cormorant) !important;
}

.btn {
  font-weight: 600;
}

.btn-brown {
  background-color: var(--brown);
  color: #fff;
  border: 2px solid var(--brown);
  transition: all 0.5s;
}

.btn-brown:not(.no-hover):hover {
  background-color: #fff;
  border-color: var(--brown);
  color: var(--brown);
  transition: all 0.5s;
}

.btn-brown-outline {
  background-color: #fff;
  color: var(--brown);
  border: 2px solid var(--brown);
  transition: all 0.5s;
}

.btn-brown-outline:not(.no-hover):hover {
  background-color: var(--brown);
  color: #fff;
  transition: all 0.5s;
}

.btn-green {
  background-color: var(--green);
  color: #fff;
  border: 2px solid var(--green);
  transition: all 0.5s;
}

.btn-green:not(.no-hover):hover {
  background-color: #fff;
  border-color: var(--green);
  color: var(--green);
  transition: all 0.5s;
}

.btn-green-outline {
  background-color: #fff;
  color: var(--green);
  border: 2px solid var(--green);
  transition: all 0.5s;
}

.btn-green-outline:not(.no-hover):hover {
  background-color: var(--green);
  color: #fff;
  transition: all 0.5s;
}

input::placeholder {
  color: #c0c0c0;
  opacity: 1;
  transition: all 0.5s;
}

input:focus::placeholder {
  opacity: 0;
  transition: all 0.5s;
}

.padding40 {
  padding-top: 4pc !important;
  padding-bottom: 4pc !important;
}

.pt-40 {
  padding-top: 4pc !important;
}

.pb-40 {
  padding-bottom: 4pc !important;
}

.margin40 {
  margin-top: 4pc !important;
  margin-bottom: 4pc !important;
}

.mt-40 {
  margin-top: 4pc !important;
}

.mb-40 {
  margin-bottom: 4pc !important;
}

.img-contain {
  object-fit: contain !important;
}

.img-cover {
  object-fit: cover !important;
}

.form-control,
.form-select {
  padding: 12px 1pc;
  border: 0;
  box-shadow: 0 0 20px #cececeb0;
}

.form-control.with-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1pc;
}

.form-control.with-icon svg {
  cursor: pointer;
}

/* input:not(.form-control):focus {
  border: 0;
  outline: 0;
} */

.flex-1 {
  flex: 1;
}

.pointer {
  cursor: pointer;
}

.bg-img {
  background-image: url("../img/bg-2.jpg");
  background-size: contain;
}

.bg-img2 {
  background-image: url("../img/bg-1.jpg");
  background-position: center bottom;
}

.bg-img3 {
  position: relative;
}

.bg-img3:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url("../img/bg-3.jpg");
  background-size: contain;
  z-index: -1;
  opacity: 0.6;
}

.bg-img4 {
  position: relative;
}

.bg-img4:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url("../img/plane2.png");
  /* background-size: contain; */
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.2;
}

.bg-img5 {
  background-image: url("../img/bg-4.jpg");
  background-size: auto;
}

.pattern2 {
  background-image: url("../img/pattern2.png");
}

.pattern3 {
  background-image: url("../img/pattern3.jpg");
  background-position: right center;
  background-repeat: no-repeat;
}

/* .list-circle li {
  list-style-type: circle;
  list-style-position: inside;
} */

input[type="checkbox"] {
  height: 17px;
  width: 17px;
  border: 2px solid #d0d0d0;
  border-radius: 0 !important;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  appearance: none;
}

input[type="checkbox"].form-check-input {
  margin: 0;
}

input[type="checkbox"].form-check-input:focus {
  border-color: #00000040;
  box-shadow: none;
}

input[type="checkbox"].form-check-input:checked {
  background-color: var(--brown);
  border-color: var(--brown);
}

input[type="radio"] {
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: var(--brown);
  border-color: var(--brown);
}

input[type="radio"]:focus {
  border-color: var(--brown);
  outline: 0;
  box-shadow: 0 0 0 0.25rem #ddbe7040;
}

.form-check label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sort-by__value-container {
  min-width: 200px;
}

input:not([class*="form-"]):focus-visible {
  outline: 0;
}

.px-4pc {
  padding-left: 4pc;
  padding-right: 4pc;
}

.fs-15 {
  font-size: 15px !important;
}

/* ============================================================================================== */

.header .right-header {
  flex: 1;
}

nav.navbar_top ul.right-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

nav.navbar_top ul.right-icons a.btn-brown {
  padding: 5px 1pc;
}

nav.navbar_top .container-fluid {
  justify-content: space-between;
}

.navbar_top .navbar-nav {
  justify-content: flex-end;
  gap: 2pc;
  margin-right: 2pc;
}

nav.navbar_top,
.topheader {
  padding-left: 3pc;
  padding-right: 3pc;
}

.topheader {
  background: #3d3935;
}

.topheader ul.social-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

nav.navbar_top {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.topheader li i {
  color: var(--brown);
}

.topheader {
  font-size: 15px;
}

.topheader li a {
  color: #fff;
  font-weight: 400;
}

.left_topbar ul {
  gap: 18px;
  display: inline-flex;
}

.left_topbar li {
  display: inline-block;
}

.right_iocn ul {
  gap: 18px;
  display: inline-flex;
}

.main_logo img {
  width: 11pc;
  border-radius: 0.375rem;
  object-fit: contain;
}

header .nav-dropdown {
  position: absolute;
  right: 0;
  background: #fff;
  z-index: 999;
  /* width: 100%; */
  padding: 10px 1pc 1pc;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

header .nav-dropdown.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}

.header .nav-dropdown img {
  width: 100%;
  height: 50vh;
  transition: all 0.5s;
}

.header {
  position: relative;
}

header .nav-dropdown img:hover {
  transform: scale(0.95);
  transition: all 0.5s;
}

.navbar_top .nav-link {
  color: #000;
  font-weight: 600;
  padding: 0 !important;
  transition: all 0.5s;
}

.navbar_top .nav-link:hover {
  color: var(--brown);
  transition: all 0.5s;
}

.text-primary {
  color: var(--brown) !important;
}

.shadowBorder {
  box-shadow: 2px 2px 5px rgb(215 219 224);
  border-radius: 5px;
}

.login_btn {
  border: 1px solid var(--brown);
  padding: 11px 22px;
  font-weight: 600 !important;
}

.slider_baner_home .swiper-button-next,
.swiper-button-prev {
  top: 44%;
}

.slider_baner_home .swiper-button-prev:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;
}

.slider_baner_home .swiper-button-next:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;
}

.slider_baner_home {
  position: relative;
}

.slider_baner_home img {
  width: 100%;
  height: 75vh;
  object-fit: cover;
}

.slider_baner_home video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.slider_caption {
  position: absolute;
  bottom: 4pc;
  left: 0;
  z-index: 2;
  right: 0;
  margin: auto;
  text-align: center;
}

.slider_caption h1 {
  font-size: 50px;
  color: #fff;
  text-shadow: 2px 2px 4px #0000004a;
}

.bg-primary {
  background-color: var(--brown) !important;
}

.slider_caption .btn-explore {
  padding: 6px 20px;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
}

.mobilebottom {
  width: 15pc;
}

.title_header h1 {
  font-size: 30px;
  font-weight: 300;
  font-family: var(--cormorant);
  margin-bottom: 0;
}

.title_header.witb-btn {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.box_location img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.box_location .absolute-text {
  background-image: url("../img/locations.png");
  background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.box_location .absolute-text p {
  margin: 8px 0 16px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.box_location {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  padding: 5px;
  border: 2px dashed lightgray;
  height: 165px;
  width: 165px;
  margin: 0 auto;
}

.porpety_footer_cared {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 20px 15px;
}

.propety_cared img {
  background-position: center center;
  background-size: cover;
  height: 16rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.porpety_footer_cared h5 {
  font-size: 1.25rem;
  margin-bottom: 3px;
}

.list_services ul {
  display: inline-flex;
  gap: 10px;
  margin-bottom: 3px;
}

.list_services li {
  color: var(--brown);
}

.porpety_footer_cared h6 {
  color: #323232;
  font-size: 16px;
}

.flex_btnarea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.book_btn a {
  border: 1px solid var(--brown);
  padding: 10px 9px;
  border-radius: 7px;
  font-weight: 500 !important;
  color: var(--brown) !important;
}

.price_right h3 {
  font-size: 17px;
  margin-bottom: 0;
  font-weight: 700;
}

.price_right p {
  text-align: right;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
}

.propety_cared {
  margin-bottom: 30px;
}

.show_btn a {
  border: 1px solid var(--brown);
  padding: 10px 9px;
  color: #fff !important;
  border-radius: 7px;
  background-color: var(--brown);
  font-weight: 500 !important;
}

.gallery_img img {
  border-radius: 10px;
}

.blogcard {
  overflow: hidden;
  background: #fff;
  height: 100%;
  /* box-shadow: 7px 8px 20px 0px #d3d3d391; */
}

.blogcard:hover {
  box-shadow: 0px 0px 20px 0px #d3d3d391;
  transition: all 0.5s;
}

.blogcard img.img-fluid {
  height: 220px;
  width: 100%;
  object-fit: cover;
  transition: all 3s;
}

.blogcard:hover img.img-fluid {
  transform: scale(1.2) rotate(4deg);
  transition: all 0.5s;
}

.blogcard ul.tags {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 1pc;
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  position: relative;
  margin-top: -20px;
}

.blogcard ul.tags li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.blogcard ul.tags svg {
  font-size: 16px;
  /* color: #161616; */
}

.blog_content {
  padding: 13px 19px;
}

.blog_content .head {
  font-weight: 600;
  line-height: normal;
  font-size: 18px;
}

.blog_content .head a {
  color: #111827;
}

.blog_content p {
  margin-bottom: 0;
  font-size: 13px;
}

.blog_content .blog-title {
  font-size: 1.5rem;
}

.blog_content p a {
  color: var(--brown);
  font-weight: 600;
}

.newsletter_section {
  height: 360px;
  background-image: url("../img/newsletter.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter_section h4 {
  font-family: Pattaya;
  font-size: 40px;
  color: #fff;
}

.newsletter_section .from_area {
  background-color: #fff;
  padding: 8px 10px;
  border-radius: 100px;
}

.newsletter_section .form-control {
  border: none;
  box-shadow: none;
  padding: 0px 21px;
}

.newsletter_section .input-group-text {
  border: 1px solid var(--brown);
  background-color: var(--brown);
  padding: 7px 15px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.border_area {
  border: 1px solid rgba(55, 65, 81);
  border-radius: 10px;
  text-align: center;
  padding: 14px 15px;
}

.border_area h5 {
  font-size: 2.25rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 0;
  color: rgba(245, 158, 11);
}

.border_area h2 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.join_area img {
  border-radius: 10px;
}

/*------------------------- footer css--------------------- */

.insta-pic {
  position: relative;
  padding: 0 4pc;
  transform: scale(1.2);
}

.insta-pic:before {
  content: "";
  position: absolute;
  height: 45px;
  width: 100%;
  background: #ffffff;
  bottom: 0;
  left: 0;
  z-index: 999999;
}

.copy-right {
  background-color: #1c1c1c;
  padding: 1pc 4pc;
}

.copy-right .social-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.copy-right a.icon {
  font-size: 18px;
}

.copy-right p,
.copy-right a {
  font-size: 14px;
  color: #fff;
}

.copy-right a:hover {
  color: var(--brown);
}

.copy-right .container-fluid,
.copy-right .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer {
  padding: 4pc 0pc 3pc;
  background-color: #161616;
  position: relative;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url("../img/brush-down.png");
  height: 20px;
}

.left_footer img {
  width: 12rem;
  border-radius: 10px;
}

.left_footer p,
.footer_list li a {
  color: #ffffff80;
}

.footer_list li a svg {
  color: var(--brown);
}

.left_foote {
  text-align: center;
}

.left_footer ul {
  display: flex;
  gap: 23px;
  margin-top: 19px;
  justify-content: center;
}

.left_footer li a {
  color: #000;
  font-size: 23px;
}

.footer_list h3 {
  letter-spacing: 0.05em;
  font-size: 0.975rem;
  font-weight: 600;
  margin-bottom: 1pc;
  color: #fff;
}

.footer_list li {
  margin-bottom: 5px;
  font-size: 14px;
}

.footer_list li a:hover {
  color: var(--brown);
}

.text_reserved {
  /* text-align: center; */
  margin-top: 30px;
  margin-bottom: 0;
}

.text_reserved h4 {
  font-weight: 700;
  font-size: 25px;
  color: #333333;
  position: relative;
}

.text_reserved h4::before {
  content: "";
  position: absolute;
  bottom: -1px;
  background-color: #333333;
  width: 100px;
  height: 2px;
}

.border-top {
  border-color: #999999;
}

/* intieray css */
.itinery_box li {
  border: 1px solid #fff;
}

.itinery_box .right {
  top: 1pc;
}

.itinery_box .right .user_inof .map_right iframe {
  height: 225px;
  width: 100%;
}

.itinery_box .right .user_inof {
  box-shadow: 0px 0px 16px #0000000f;
  border-radius: 10px;
  padding: 2pc 2pc;
  /* border: 1px solid #ddd;   */
  background: #fff;
}

.itinery_box .right .user_inof input {
  background-color: #fff;
  border: 1px solid #ddd;
  font-weight: 600;
  color: var(--brown);
  cursor: pointer;
  letter-spacing: 2px;
  width: 100%;
  padding: 10px 1pc;
  background-color: #fff !important;
  border-radius: 4px;
}

.itinery_box .right .user_inof input:focus-visible {
  background-color: #fff;
  outline: none;
}

.itinery_box .right .price-breakup li {
  border-bottom: 1px solid #ddd;
  gap: 10px;
  padding: 10px 0;
}

.itinery_box .right .price-breakup li:last-child {
  border: 0;
}

.itinery_box .right .price-breakup .small {
  line-height: normal;
  font-size: 13px;
}

.itinery_box .container-fluid {
  padding: 0px 15pc;
}

.user_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.user_img .img-fluid {
  width: 80px;
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 100px;
}

.user_img .absolute_img {
  position: absolute;
  top: 15px;
  right: 17px;
  width: 25px;
}

.iconstart {
  color: var(--brown);
}

.about_user p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

.stay_section h3 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
  color: #000;
}

.stay_section p {
  color: #5a5e5f;
  font-size: 16px;
  font-weight: 500;
}

.stay_section li {
  font-size: 16px;
  list-style: disc;
  color: #5a5e5f;
  margin-bottom: 10px;
}

.stay_section ul {
  padding-left: 15px;
}

.btn-host_user .btn-host {
  width: 100%;
  background-color: var(--brown);
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 9px 10px;
  border-radius: 15px;
}

.pyment_text {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #c0c0c0;
}

.list_header_rightside ul {
  display: flex;
  gap: 20px;
  justify-content: end;
}

.list_header_rightside li a {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: block;
  /* background-color: var(--brown); */
  text-align: center;
  line-height: 40px;
}

.list_header_rightside li a svg {
  font-size: 20px;
}

.list_header_rightside li .iocnl.share {
  background-color: #edf0ef;
  color: #5a5e5f;
}

.list_header_rightside li .iocnl.heart {
  background-color: #ff8483;
  color: #fff;
}

.list_header_rightside li .iocnl.dot {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  color: #000;
}

.hding_title h1 {
  font-size: 37px;
  font-family: larken;
  font-weight: 700;
  margin-bottom: 5px;
}

.hding_title svg {
  color: var(--brown);
}

.list_tabs_vertical {
  top: 1pc;
  width: max-content;
  margin: 0 auto;
}

.list_tabs_vertical ul {
  background-color: #f3f5f5;
  padding: 10px;
  border-radius: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list_tabs_vertical li a.active {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
}

.list_tabs_vertical li a {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 8px;
}

.list_tabs_vertical li a img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.list_tabs_vertical li a .itnerys_iocn {
  font-size: 22px;
}

.guest-absolute {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 99;
  box-shadow: 0 0 20px #00000020;
  border-radius: 5px;
  padding: 1pc;
  margin-top: 10px;
  min-width: 300px;
}

.guest-absolute li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  margin-bottom: 10px;
}

.guest-absolute li p:first-child {
  color: #000;
  line-height: normal;
}

.guest-absolute li:last-child {
  margin: 0;
}

.quantity-box {
  display: flex;
  align-items: center;
}

.quantity-box span {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #424242;
}

.quantity-box span.bg {
  font-size: 22px;
  border: 1px solid #ddd;
  background: #fff !important;
  border-radius: 5px;
  cursor: pointer;
}

.date_card .date_selectcheck {
  position: relative;
  text-align: center;
}

.date_card .date_selectcheck .dawon_arrow {
  color: var(--brown);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 21px;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.heading_date h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.price_section {
  padding-left: 15px;
  display: flex;
  align-items: baseline;
  justify-content: end;
}

.price_section h3 {
  color: #5a5e5f;
  font-size: 19px;
  font-weight: 600;
}

.price_section h4 {
  color: var(--brown);
  margin-bottom: 0;
  font-weight: 600;
  font-size: 35px;
}

.price_section h5 {
  color: #c0c0c0;
  text-decoration: line-through;
}

/* .faciluty_section {
  margin-top: 20px;
} */
.faciluty_section h3 {
  font-weight: 500;
  font-size: 16px;
}

.card_facilut span {
  color: #5a5e5f;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.card_facilut img {
  width: 22px;
  margin-left: 8px;
}

.card_facilut .numberlist_fr {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card_facilut .numberlist_fr svg {
  color: var(--brown);
  font-size: 12px;
  margin-left: 0 !important;
}

.dicription_area {
  margin-top: 20px;
}

.headin_discri {
  color: #000000;
  font-weight: 600;
}

.conten_decipt {
  font-size: 15px;
  line-height: 25px;
}

.heading_title h3 {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1pc;
  font-family: larken;
  font-weight: 700;
  margin-bottom: 5px;
}

.conten_decipt a {
  font-weight: 500;
  color: var(--brown);
  text-decoration: none;
  display: block;
}

.card__offer_list li {
  /* border: 1px dashed #888888; */
  /* border-radius: 4px; */
  /* display: flex; */
  padding: 9px;
  align-items: center;
  margin-bottom: 15px;
}

.card__offer_list li img {
  width: 20px;
  margin-right: 12px;
}

.card__offer_list h3 {
  margin-bottom: 0;
  font-size: 16px;
  /* color: #888888; */
  color: rgb(59, 57, 63);
}

.btn.btn-show {
  width: 100%;
  background-color: var(--brown);
  padding: 9px;
  color: #fff;
  font-weight: 600;
}

.border_date {
  overflow: hidden;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.prosserbar {
  background-color: #d9d9d9;
  width: 100%;
  height: 7px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.progress-bars {
  position: absolute;
  background-color: #5a5e5f;
  width: 70%;
  height: 7px;
}

.flex_prosser {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.flex_prosser h3 {
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 10px;
}

.w-8 {
  width: 50%;
}

.flex_prosser span {
  margin-left: 10px;
}

.revie_card_header {
  display: flex;
  align-items: center;
}

.testimonial_img img {
  width: 50px;
}

.user_nametestimonail {
  margin-left: 10px;
}

.user_nametestimonail h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

.user_nametestimonail p {
  color: #888888;
  font-size: 16px;
  margin-bottom: 0;
}

.testimoan_body {
  margin-top: 10px;
}

.testimoan_body p {
  color: #888888;
  font-size: 14px;
  font-weight: 500;
}

.btn.btn-show {
  background-color: var(--brown);
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}

.map_text p {
  color: #888888;
  font-size: 17px;
}

.link_tink h5 {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.link_tink li {
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 400;
}

.link_tink li a {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.homeslider1 img {
  width: 100%;
  height: 138px;
  object-fit: cover;
}

.slider_hkaise .swiper {
  width: 100%;
  height: 600px;
}

.slider_hkaise .swiper-slide {
  margin-bottom: 10px;
}

.slider_hkaise .swiper-button-prev {
  display: none;
}

.slider_hkaise .swiper-button-next {
  display: none;
}

.swiper_left img {
  height: 70px;
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
  margin-top: 6px;
  cursor: pointer;
}

.roomhotalcardheader {
  width: 400px;
}

.roomhotalcard tbody td {
  padding: 1pc;
}

.room-options li {
  margin-bottom: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.big_img_right img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.img-relexbad img {
  width: 100%;
  height: 400px;
}

.gaust_list {
  position: absolute;
  background-color: #fff;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  width: 100%;
  margin: 0px 10%;
  border-radius: 12px;
  text-align: center;
  overflow: hidden;
}

.gaust_list li {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  background-color: #fff;
}

.gaust_list li:hover {
  background-color: #f3f5f5;
}

.list_gaut {
  cursor: pointer;
  font-weight: 600;
  color: var(--brown);
  margin-top: 7px;
  letter-spacing: 2px;
}

.hidden1 {
  caret-color: transparent;
  cursor: pointer;
}

.Itinerary_slide img {
  position: relative;
  height: 300px;
  width: 100%;
  object-fit: cover;
  transition: all 0.5s;
}
.Itinerary_slide:hover img{
  transition: all 0.5s;
  transform: scale(1.1);

}
.Itinerary_slide {
  position: relative;
  background-color: #f1e5ca;
  border-radius: 10px;
  overflow: hidden;
}
.Itinerary_slide::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, rgb(0 0 0 / 90%) 0%, transparent 70%);
}










.Itinerary_slide .absolute-content {
  position: absolute;
  top: 20px;
  left: 10px;
  /* width: 30px; */
  /* height: 30px; */
  background-color: #fff;
  border-radius: 50px;
  color: #5a5e5f;
  margin-bottom: 0;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  padding: 4px 17px;
  font-size: 13px;
}

.itnery_slider .swiper-button-prev:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;
}

.itnery_slider .swiper-button-next:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;
}

.itnery_slider .content{
  position: absolute;
  bottom: 4px;
  padding-bottom: 10px;
  z-index: 9999;
  width: 100%;
    text-align: center;
}
.itnery_slider .content h5{
  color: #fff;
}
.rdrCalendarWrapper {
  color: #000000;
  font-size: 14px !important;
}

.rdrEndEdge {
  background-color: var(--brown) !important;
}

.map_itiners iframe {
  border-radius: 15px;
}

.btn_propery {
  margin: 18px 17px;
}

.btn_propery .btn-host {
  padding: 8px 18px;
  border-radius: 9px;
  color: #fff;
  font-size: 18px;
  width: 100%;
  font-weight: 600;
  background-color: var(--brown);
}

.box_right_itners {
  border: 1.12821px solid rgba(0, 0, 0, 0.1);
  border-radius: 6.76923px;
  padding: 5px;
  min-height: 106px;
}

._box_header_itners h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  color: #ddbe70;
}

._box_header_itners {
  display: flex;
  justify-content: space-between;
}

.box_right_itners p {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 500;
  font-size: 14px;
}

.box_right_itners p {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 500;
  font-size: 14px;
}

.link_view .btn-view {
  color: #1e2031;
  font-weight: 700;
  font-size: 13px;
  padding: 0;
  text-decoration: underline !important;
}

._box_header_itners img {
  width: 30px;
}

.left_footer .btn-join {
  border: 1px solid var(--brown);
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  padding: 10px 47px;
  color: var(--brown);
}

.left_footer .btn-join:hover {
  background-color: var(--brown);
  color: #fff;
}

.hotal_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 1pc;
  column-gap: 10px;
}

.hotal_list ul {
  display: flex;
  align-items: center;
  gap: 5px;
}

.hotal_list ul li {
  font-weight: 600;
  color: #333333;
  position: relative;
}

.hotal_list ul li:first-child::after {
  color: #555555;
}

.hotal_list ul li::after {
  content: "|";
  color: #999999;
  padding-left: 5px;
}

.hotal_list ul li:last-child::after {
  content: "";
}

.hotal_list ul li a {
  font-weight: 400;
  font-size: 14px;
  color: #555555;
}

.banner-tabs .box {
  align-items: center;
}

.banner-tabs {
  padding: 1pc 0pc;
  border-radius: 0 10px 10px 0;
  position: relative;
  margin: 0 auto;
}

.home-banner-tabs {
  width: max-content;
  margin: 2pc auto 0;
  border-radius: 0;
  padding: 10px 2pc;
}

.banner-tabs .outer-inner {
  display: flex;
  align-items: flex-end;
  column-gap: 1pc;
}

.mobile100 {
  width: 100%;
  margin-top: 10px;
}

.title_header.witb-btn {
  display: unset;
}

.banner-tabs .btn-brown {
  border-radius: 0;
  padding: 10px 2pc;
}

.banner-tabs .box label {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 12px;
}

.banner-tabs .box .form-control {
  padding: 8px 1pc;
  border: 1px solid #ccc;
  box-shadow: none;
  font-size: 14px;
}

.banner-tabs .box .date-picker-input input {
  border: 0;
  width: 100%;
  background: transparent;
}

.react-datepicker-popper {
  z-index: 99999 !important;
}

.location-dropdown {
  position: absolute;
  height: 300px;
  width: max-content;
  text-align: left;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 15px #00000020;
  z-index: 30;
  overflow: hidden scroll;
  z-index: 9999;
}

.location-dropdown::-webkit-scrollbar {
  width: 0;
}

.location-dropdown>p.text-muted {
  padding: 10px;
}

.location-dropdown p.text-muted {
  font-size: 13px;
  font-weight: 500;
}

.location-dropdown p {
  margin-bottom: 0;
}

.location-dropdown .list {
  border-top: 1px solid #eee;
}

.location-dropdown .list li {
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  gap: 8px;
  padding: 5px 10px;
}

.location-dropdown .list li p.text-dark {
  font-size: 14px;
  font-weight: 500;
}

.location-dropdown .list li p.text-muted {
  font-size: 12px;
}

.banner-tabs .box li.inner {
  /* width: 20%; */
  text-align: left;
}

.banner-tabs .box li.inner:not(:last-child) {
  border-right: 3px solid #ececec;
}

.text-default {
  color: #888 !important;
}

.places-box {
  background-color: #fff;
  /* box-shadow: 7px 8px 20px 0px #d3d3d391; */
  transition: all 0.5s;
  overflow: hidden;
  border-radius: 15px;
}

.places-box:hover {
  box-shadow: 0px 0px 20px 0px #d3d3d391;
  transform: scale(1.03);
  transition: all 0.5s;
}

.places-box .image {
  position: relative;
}

.places-box ul.points {
  position: absolute;
  top: 1pc;
  left: 0;
}

.places-box ul.points li {
  background-image: url("../img/tags.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-size: 14px;
  padding: 4px 1.5pc 4px 1pc;
  font-weight: 500;
  letter-spacing: 1px;
  filter: drop-shadow(1px 1px 0px #fff);
}

.places-box img {
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.places-box ul.tags {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  position: relative;
  margin-top: -20px;
}

.places-box .content h4 {
  font-size: 18px;
  /* font-weight: 600; */
  margin-bottom: 0;
}

.places-box .content h4 a {
  color: #000;
}

.places-box .content h4 a:hover {
  color: var(--brown);
}

.places-box .content {
  padding: 10px 1pc 1pc;
}

.places-box .content p.desp {
  font-size: 14px;
  margin-bottom: 5px;
}

.places-box .content .price {
  display: flex;
  align-items: flex-end;
}

.places-box .content .price p {
  font-weight: 500;
  font-size: 12px;
}

.places-box .content .price h5 {
  font-weight: 700;
  margin: 0;
}

.places-box .content .price+p {
  font-size: 10px;
}

.property-listing .left {
  text-align: center;
  background-color: #f1e5ca;
  padding: 2pc;
}

.property-listing .left p {
  line-height: 30px;
}

.property-listing .left ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 1pc;
}

.property-listing .left li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: #000;
  border-right: 1px solid #000;
  padding: 0 10px;
  font-size: 14px;
}

.property-listing .left li:last-child {
  border: 0;
}

.property-listing .left li span {
  padding: 5px;
  height: 26px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.property-listing {
  position: relative;
}

.property-listing:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: var(--bg);
  height: 100%;
  width: 40%;
  z-index: 1;
  /* opacity: 0.5; */
}

.property-listing .right {
  display: flex;
  gap: 1pc;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 4;
}

.property-listing .right img {
  width: calc(50% - 1pc);
  object-fit: cover;
  height: 300px;
}

.leftimg {
  background-image: url(../img/line_newltter.png);
  height: 15px;
  width: 76%;
  background-size: cover;
}

.rightimg {
  background-image: url(../img/line_newltter.png);
  height: 15px;
  width: 71%;
  position: absolute;
  right: -4px;
  background-size: cover;
  bottom: 0;
}

.newsletter_card h3 {
  font-family: "Cormorant Garamond";
  font-size: 30px;
  font-weight: 700;
}

.newsletter_card p {
  color: #bfbfbf;
  font-size: 16px;
  font-weight: 400;
}

.news_letter_input .form-control {
  padding: 10px 19px;
  border: 2px solid var(--brown);
  box-shadow: none;
}

#basic-addon2 {
  padding: 10px 19px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--brown);
  border: 2px solid var(--brown);
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.reserved_text P {
  margin-bottom: 0;
}

.adult_section .css-13cymwt-control {
  border: none !important;
  cursor: pointer;
}

.adult_section .css-1u9des2-indicatorSeparator {
  width: 0;
}

.adult_section .css-tj5bde-Svg {
  color: var(--brown);
}

.gaust_list1 p {
  margin-bottom: 0;
}

.home-benefits .box {
  position: relative;
  background-color: #fff;
}

.home-benefits .box .img {
  background-color: var(--brown);
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin-top: -40px;
}

.home-benefits .box .img img {
  height: 100%;
  width: auto;
  object-fit: contain;
  padding: 16px;
}

.home-benefits .box .icon img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  padding: 10px;
  border-radius: 15px;
}

.home-benefits .img,
.home-benefits p {
  position: relative;
  z-index: 10;
}

.home-benefits .box .content {
  display: flex;
  gap: 1pc;
}

.home-benefits p {
  color: #000;
  padding: 10px 10px 1.5pc 0px;
  font-weight: 600;
}

.heigit_list ul {
  display: flex;
  /* justify-content: space-between; */
  gap: 15px;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.heigit_list ul {
  display: flex;
  gap: 10px;
}

.heigit_list li {
  width: 30%;
  display: -webkit-inline-box;
  display: flex;
  align-items: center;
  color: #000;
}

/* .heigit_list li span {
  color: rgb(125, 123, 127);
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
} */

.reviews_section {
  margin-bottom: 15px;
}

.numberlist_fr svg {
  margin-left: 4px;
  font-size: 20px;
  color: #5a5e5f;
}

.property-gallery {
  height: 500.872px;
}

#property-gallery {
  display: flex;
  /* justify-content: space-between; */
  border-radius: 8px;
  background-color: #fff;
  /* height: 500px !important; */
  overflow: hidden;
  gap: 10px;

  flex-direction: row-reverse;
}

#property-gallery img {
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
}

.property-gallery-left {
  height: 100%;
  z-index: 99;
  width: 85%;
}

.property-gallery-left img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.ng-star-inserted {
  position: relative;
}

.new_propery_gallert .right {
  /* width: calc(25% - var(--image-padding)); */
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
  background-color: #fff;
  gap: 10px;
}

.ng-star-inserted img {
  width: 100%;
  height: 34.5vh;
  object-fit: cover;
}

.ng-star-inserted img:hover {
  opacity: 0.7;
}

.ng-star-inserted {
  /* height: calc(250px - 5px); */
  z-index: 99;
}

.property-gallery-left img:hover {
  opacity: 0.7;
}

.all-photos-btn-text {
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: center;
  color: #fff;
  padding: 10px;
  font-size: 15px;
  width: 30%;
  background: rgb(30 30 30 / 55%);
  border-radius: 10px;
}

.wraper_itnerys {
  width: 100vw;
  height: 100%;
  z-index: 1050;
  overflow-y: hidden;
  flex-direction: column;
  background: #faf7f2;
}

.wrapper {
  /* width: 100vw; */
  height: 100%;
  z-index: 1050;
  overflow-y: hidden;
  flex-direction: column;
  background: #faf7f2;
}

.page-banner {
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  align-items: flex-end;
  box-shadow: inset 0 0 0 2000px #00000080;
}

.page-banner h1,
.page-banner li,
.page-banner a,
.page-banner p {
  color: #fff;
}

.page-banner h1 {
  font-size: 60px;
}

.page-banner ul {
  display: flex;
}

.collection-box {
  height: 450px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: inset 0 0 0 2000px #00000033; */
  padding: 2pc;
  transition: all 1s;
  border-radius: 10px;
  position: relative;
}

/* .our-collection .col-12:nth-child(even) .collection-box {
  margin-top: 2pc;
} */

.collection-box:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, transparent 65%, #000000a3);
  border-radius: 10px;
}

.collection-box h4,
.collection-box p {
  color: #fff;
  position: relative;
  z-index: 4;
  margin-bottom: 0;
}

.collection-box p {
  display: none;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 500;
}

.collection-box:hover {
  background-position: bottom right;
  /* border-radius: 20px; */
  transition: all 1s;
  /* box-shadow: inset 0 0 0 2000px #2a2a2aa0; */
}

/* .collection-box:hover:before {
  display: none;
} */

/* .collection-box:hover p {
  display: block;
  transition: all 0.5s;
} */

.home-banner {
  background-image: url("../img/Readerscottage/Readerscottageimg11.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  text-align: center;
  color: #fff;
  box-shadow: inset 0 0 0 2000px #00000060;
}

.home-banner h5 {
  margin: 1pc 0 2pc;
  font-weight: 600;
  font-size: 18px;
}

.home-banner a.btn {
  border-radius: 0;
  padding: 10px 1pc;
}

.blog-detail-page {
  width: 90%;
  margin: 0 auto;
}

.blog-detail {
  background-color: transparent;
}

.blog-detail img {
  height: 400px !important;
  border-radius: 15px;
  transition: all 1s ease-in-out;
}

.blog-detail:hover img {
  transform: none !important;
  transition: all 1s ease-in-out;
}

.blog-detail .image {
  border-radius: 15px;
}

.blog_content p {
  width: 93%;
  text-align: start !important;
}

.blog_content p img {
  text-align: center !important;
}

.blog_content img {
  width: 100%;
  object-fit: cover;
  height: 450px !important;
}

.blog-detail ul.tags {
  padding: 10px 1pc;
  margin-left: 2pc;
  font-size: 14px;
  gap: 1pc;
}

.blog-detail ul.tags svg {
  color: var(--brown);
}

.blog-detail .blog_content p {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 30px;
  color: #555;
  font-weight: 500;
}

.our-director .left img {
  height: 370px;
  width: 370px;
  border-radius: 50%;
  object-fit: cover;
}

.our-director {
  background-image: url("../img/bg-1.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.about-company .left img {
  height: 300px;
}

.about-company .right img {
  max-height: 600px;
}

.why-us .box {
  text-align: center;
  padding: 2pc 3pc;
  border-right: 1px solid #ccc;
}

.why-us .box .icon {
  font-size: 45px;
}

.why-us .box h4 {
  font-size: 28px;
  font-weight: bold;
}

.our-team {
  text-align: center;
  padding: 5px;
  margin: 10px;
  transition: all 0.5s;
}

.our-team:hover {
  box-shadow: 0 0 20px #00000020;
  transition: all 0.5s;
}

.our-team img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 10px;
}

.our-team h5 {
  font-weight: 600;
}

.our-journey {
  position: relative;
  background-color: var(--bg);
  padding: 3pc;
  margin: 0 2pc;
  transition: all 0.5s;
}

.our-journey:hover {
  background-color: var(--brown);
  transition: all 0.5s;
}

.our-journey:hover .num {
  color: #000 !important;
  transition: all 0.5s;
}

.our-journey .num {
  font-weight: 700;
  position: absolute;
  top: -25px;
  left: 3pc;
  margin-bottom: 0;
  transition: all 0.5s;
}

.our-journey h4 {
  font-size: 18px;
  font-weight: 600;
}

.our-journey:hover p {
  color: #fff;
  transition: all 0.5s;
}

.our-journey p {
  font-size: 14px;
  margin-bottom: 0;
  transition: all 0.5s;
}

.journey-right {
  margin-top: 6pc;
}

.journey-row {
  position: relative;
}

.journey-row:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: var(--brown);
  right: 50%;
}

.our-journey:before {
  position: absolute;
  content: "";
  background: var(--brown);
  width: 100%;
  height: 2px;
  z-index: -1;
}

.journey-left:before {
  bottom: 50%;
  right: -44px;
}

.journey-right:before {
  bottom: 50%;
  left: -44px;
}

.icon_amenties {
  border-radius: 50%;
  padding: 8px;
  width: 40px;
  height: 40px;
  border: 0.200000003px solid #707070;
  position: relative;
}

.list_ung {
  display: block;
  width: 35px;
  height: 35px;
  border: .5px solid #e6e6e6;
  text-align: center;
  border-radius: 5px;
  line-height: 31px;
  margin-right: 10px;
}
.heigit_list li span{
  font-size: 13px;
  font-weight: 600;
}

.custom-modal-parent {
  position: relative;
  z-index: 9999999;
}

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  min-width: 100%;
  z-index: 9999;
}

.custom-modal label {
  font-size: 14px;
  margin-bottom: 4px;
}

.custom-modal:focus-visible {
  outline: 0;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  backdrop-filter: blur(1px);
}

.custom-modal-body {
  overflow: hidden;
}

.colsebtn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
}

.btn_colsesize {
  font-size: 30px;
  cursor: pointer;
}

.custom-modal .form-group {
  margin-bottom: 10px;
}

.home-popup {
  background-color: #fff;
  width: 100%;
  height: 100vh;
}

.property-listing-steps {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 8pc;
  /* background-image: url('../img/family.png');
  background-position: center 101%;
  background-repeat: repeat-x;
  background-size: 12pc; */
}

.property-listing-steps .amenities .form-check {
  margin-bottom: 1pc;
  width: 30%;
}

.property-listing-steps label {
  color: #878f9d;
  font-size: 13px;
  font-weight: 500;
}

.property-listing-steps .form-control,
.property-listing-steps .form-select {
  background-color: #fafafc;
  border: 1px solid #f6f4ff;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
}

.property-listing-steps::before {
  opacity: 0.8;
  background-size: auto;
}

.property-listing-steps .bottom-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2pc;
}

.property-listing-steps .bottom-btns .btn {
  padding: 10px 2pc;
}

.property-listing-steps .main_logo img {
  width: 230px;
}

.property-listing-steps .traveller {
  background-image: url("../img/traveller.gif");
  animation: traveller 30s linear 0s infinite;
  position: absolute;
  bottom: -8px;
  height: 80px;
  width: 80px;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes traveller {
  0% {
    left: 0%;
  }

  15% {
    left: 15%;
  }

  25% {
    left: 25%;
  }

  40% {
    left: 40%;
  }

  60% {
    left: 60%;
  }

  80% {
    left: 80%;
  }

  100% {
    left: 100%;
  }
}

.property-listing-steps .container {
  position: relative;
  z-index: 10;
}

.property-listing-steps .inner {
  padding: 2pc;
  /* border: 1px solid #cccccc34;
  box-shadow: 0 0 20px #00000020; */
}

.property-listing-steps h5 {
  font-weight: 600;
}

.contact-info .box {
  text-align: center;
  border: 4px solid var(--bg);
  height: 100%;
  padding: 2pc 3pc;
  background-color: #fff;
}

.contact-info .box a {
  color: var(--brown);
}

.contact-info .box .icon {
  color: var(--brown);
  height: 70px;
  transition: all 0.5s;
  width: 70px;
  margin: 0 auto 1pc;
  border: 2px solid var(--bg);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.contact-info .box:hover .icon {
  background: var(--brown);
  border-color: var(--brown);
  color: #fff;
  transition: all 0.5s;
}

.contact-info .box:hover h4 {
  color: var(--brown);
  transition: all 0.5s;
}

.contact-info .box h4 {
  transition: all 0.5s;
}

.contact-form .form-control {
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.shopfilters-title {
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 1pc;
  line-height: normal;
  color: #000;
}

.shopfilters-list {
  color: #36454f;
  max-height: 210px;
  overflow: auto;
  padding: 5px 0;
}

.shopfilters-list::-webkit-scrollbar {
  width: 5px;
}

.shopfilters-list::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.shopfilters-list::-webkit-scrollbar-thumb {
  background-color: var(--brown);
}

.shopfilters-list-title {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.shopfilters-list-title label,
.shopfilters-list-title input {
  cursor: pointer;
}

.shopfilters-list-title input[type="checkbox"] {
  appearance: none;
  height: 15px;
  width: 15px;
  border: 1px solid #d0d0d0;
  position: relative;
  background-color: #fff;
}

.shopfilters-list-title input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../img/check.png");
  background-size: contain;
  background-color: #fff 0;
  transform: scale(0);
  transition: all 0.1s;
  opacity: 0;
}

.shopfilters-list-title input[type="checkbox"]:checked:before {
  opacity: 1;
  transform: scale(1);
  transition: all 0.1s;
}

.shopfilters-list-title input[type="checkbox"]:not(:checked):before {
  opacity: 0;
  transform: scale(0);
  transition: all 0.1s;
}

.shopfilters-box {
  margin-bottom: 1pc;
}

.hotel-listing {
  width: 95%;
  margin: 0 auto;
}

.price-filter {
  height: 4px !important;
}

.price-filter .range-slider__range {
  background: var(--brown) !important;
  transition: height 0.3s;
}

.price-filter .range-slider__thumb {
  background: var(--brown) !important;
  transition: transform 0.3s;
  width: 20px !important;
  height: 20px !important;
}

.price-filter .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.1) !important;
}

.price-filter .range-slider__range[data-active] {
  height: 14px;
}

.hotel-listing .places-box ul.tags {
  font-size: 12px;
}

ul.blog-filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

ul.blog-filters li {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 0 15px #00000020;
  cursor: pointer;
  padding: 10px 2pc;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.itiner_body ul.blog-filters li {
  border-radius: 4px;
}

.itiner_body .listnighchekout ul li {
  list-style: circle;
  list-style-position: inside;
}

ul.blog-filters li.active {
  background: var(--brown);
  color: #fff;
}

.home-blog {
  display: flex;
  background: #fff;
  padding: 5px;
  box-shadow: 0 0 20px #00000020;
  height: 100%;
}

.home-blog .image {
  width: 45%;
  height: 265px;
}

.home-blog img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.5s;
}

.home-blog:hover img {
  object-position: right;
  transform: scale(1.2);
  transition: all 0.5s;
}

.home-blog .content {
  padding: 1pc;
}

.home-blog .content .head a {
  color: #000;
}

.home-blog .content ul.tags {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0;
}

.home-blog .content ul.tags li {
  display: flex;
  gap: 5px;
  align-items: center;
}

.home-blog .content p {
  font-size: 15px;
  margin-bottom: 0;
}

.home-blog .content p a {
  color: var(--green);
  font-weight: 600;
}

.home-blog .content p span {
  color: #000;
  font-weight: 500;
}

.home-testimonial .box {
  padding: 2pc;
  border-radius: 15px;
  text-align: center;
  height: 100%;
  /* margin-top: 2pc; */
  transition: all 0.5s;
}

.home-testimonial .box .image img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 0.5s;
  object-fit: cover;
}

/* .home-testimonial .swiper-slide-active .box {
  background-color: var(--brown) !important;
  margin-top: 0;
  transition: all 0.5s;
}

.home-testimonial .swiper-slide-active .box p.desp {
  color: #fff;
  transition: all 0.5s;
}

.home-testimonial .swiper-slide-active .box h6.brown {
  color: #000 !important;
  transition: all 0.5s;
}

.home-testimonial .swiper-slide-active .box .image img {
  border-color: #fff;
  transition: all 0.5s;
}

.home-testimonial .swiper-slide-active .box .place {
  color: #000;
  transition: all 0.5s;
} */

.home-testimonial .box .desp {
  margin: 1pc 0;
}

.home-testimonial .box .desp p {
  margin-bottom: 0;
  color: #000;
  transition: all 0.5s;
  overflow: hidden;
}

.home-testimonial .box .desp p+a.brown {
  font-size: 15px;
}

.home-testimonial .box .place {
  color: var(--brown);
  font-weight: 500;
  font-size: 15px;
  margin-top: 5px;
  transition: all 0.5s;
}

.filters-2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  column-gap: 1pc;
  width: max-content;
}

.filters-2 li {
  cursor: pointer;
  font-weight: 600;
  padding: 0 15px 8px;
}

.filters-2 li.active {
  color: var(--brown);
  border-bottom: 3px solid var(--brown);
}

.newsletter-2 {
  border-radius: 15px;
  text-align: left;
  height: auto;
  padding: 80px 2pc;
  width: 90%;
  margin: 0 auto;
}

.youtube-video iframe {
  height: 400px;
  border-radius: 20px;
}

.map iframe {
  border-radius: 15px;
}

.trending-blog .img {
  width: 30%;
}

.trending-blog img {
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.trending-blog {
  display: flex;
  column-gap: 1pc;
  align-items: center;
  box-shadow: 0 0 20px #00000020;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.listing-banner {
  width: 90%;
  margin: 0 auto;
  background-attachment: local;
  background-position: center;
}

.listing-banner h1 {
  font-size: 40px;
  margin-bottom: 1pc;
}

.accordion {
  --bs-border-color: var(--brown);
  --bs-accordion-btn-focus-border-color: var(--brown);
  --bs-accordion-active-color: #000;
  --bs-accordion-active-bg: #fff9eb;
  --bs-accordion-btn-active-icon: url("../img/aroow_down.svg");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #ddbe7040;
  --bs-accordion-btn-padding-y: 20px;
}

.accordion-body p:last-child {
  margin-bottom: 0;
}

.accordion-button {
  font-size: 17px;
}

.listing-about img {
  height: 350px;
}

.listing-about li {
  margin-bottom: 10px;
  font-weight: 500;
}

.listing-benefits {
  text-align: center;
  height: 100%;
  padding: 2pc 1pc;
  border-radius: 5px 5px;
  box-shadow: 0 2px #cccccc;
}

.listing-benefits h6 {
  font-size: 17px;
  font-weight: 600;
}

.listing-benefits p {
  font-size: 15px;
}

.listing-boxes {
  background-color: #e4dfd78f;
  box-shadow: none;
  padding: 0;
  border-radius: 15px;
  align-items: center;
}

.listing-boxes img {
  border-radius: 15px 0 0 15px;
}

.listing-boxes .content p {
  color: #000;
  margin-bottom: 1pc;
}

.listing-boxes .content {
  padding: 2pc;
}

.listing-boxes .image {
  height: 350px;
}

.listing-boxes .content .head {
  font-size: 25px;
}

.listing-boxes.reverse {
  flex-direction: row-reverse;
}

.listing-boxes.reverse img {
  border-radius: 0 15px 15px 0;
}

.login-page {
  overflow: hidden;
  background-color: #e4dfd78f;
}

.login-page:before {
  z-index: 1;
}

.login-page>.row {
  position: relative;
  z-index: 10;
}

.login-page .left img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.login-page .right {
  padding: 2pc;
}

.login-page .right .inner {
  max-width: 70%;
  margin: 0 auto;
}

.home-popup a {
  cursor: pointer;
}

.gallery-page {
  max-height: 70vh;
  overflow: hidden scroll;
}

.gallery-page::-webkit-scrollbar {
  width: 5px;
}

.gallery-page::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.gallery-page::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.gallery-page .image {
  overflow: hidden;
}

.gallery-page img {
  transition: all 1s;
  /* height: 69vh; */
  height: 300px;
  cursor: pointer;
}

.gallery-page img:hover {
  transform: scale(1.3);
  transition: all 1s;
}


.gallery-page .swiper-button-prev:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;
  left: 8px;
}

.gallery-page .swiper-button-next:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  right: 10px;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;
}









.modal-header .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1pc;
  z-index: 5000;
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5pc 0 1pc;
}

.timeline li {
  position: relative;
}

.timeline li p {
  position: absolute;
  left: -15px;
  font-weight: 600;
}

.timeline li.half p {
  color: var(--green);
}

.timeline li:not(:last-child) {
  margin-right: 150px;
}

.timeline li.half .dot {
  background-color: var(--green);
  height: 30px;
  width: 30px;
  position: relative;
}

.timeline li.half .dot:before {
  content: "";
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timeline li.half .half-filled {
  position: absolute;
  height: 100px;
  width: 110px;
  top: 50%;
  transform: translateY(-50%);
  left: 0%;
}

.timeline li.half .half-filled .half-line {
  background-color: var(--green);
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.timeline li.half .half-filled img {
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  right: -5px;
  height: auto;
  width: 50px;
  object-fit: contain;
}

.timeline li.half:last-child .half-line {
  display: none;
}

.timeline li.half:last-child .half-filled {
  left: -3pc;
}

.timeline li.half:last-child .half-filled img {
  right: auto;
  left: 0;
}

.timeline li:not(:last-child) .empty-line {
  background-color: #d9d9d9;
  height: 2px;
  width: 150px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
}

.timeline li:not(:last-child) .full-filled {
  background-color: var(--green);
  height: 2px;
  width: 150px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  z-index: 4;
}

.timeline li:not(:last-child).full .dot {
  background-color: var(--green);
}

.timeline .dot {
  height: 20px;
  width: 20px;
  background-color: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
}

.timeline.verticle-timeline {
  flex-direction: column;
}

.timeline.verticle-timeline li {
  margin-right: 0;
  margin-bottom: 100px;
}

.timeline.verticle-timeline li:not(:last-child) .empty-line {
  width: 2px;
  height: 135px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}

.timeline.verticle-timeline li:not(:last-child) .full-filled {
  width: 2px;
  height: 135px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}

.timeline.verticle-timeline li .dot {
  z-index: 4;
}

.timeline.verticle-timeline li.half .half-filled {
  width: 2px;
  height: 75px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}

.slidetopbanner .swiper-slide img {
  width: 100%;
  height: 95vh;
}

.slidetopbanner {
  position: relative;
}

.slidetopbanner::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

#date_section {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(242, 242, 242);
  box-sizing: border-box;
  box-shadow: rgb(221 221 221 / 35%) 0px 0px 50px 4px;
  border-radius: 10px;
  padding: 20px 40px;
}

.newboxshadow {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(242, 242, 242);
  box-sizing: border-box;
  box-shadow: rgb(221 221 221 / 35%) 0px 0px 50px 4px;
  border-radius: 10px;
  padding: 50px 40px;
}

.policies_cont li {
  list-style: disc;
}

.policies_cont ul {
  padding-left: 20px;
}

.itneryfaqsection .accordion {
  border: none;
}

.itneryfaqsection .accordion-item {
  border: none;
  border-bottom: 1px solid #ddd;
}

.itneryfaqsection .accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: none;
}

.experience-modal .left .img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* .testimonial-modal .left .img {
  height: 50vh;
} */
.experience-modal .swiper {
  height: 50vh;
}

.experience-modal .swiper-button-next:after,
.experience-modal .swiper-button-prev:after {
  color: #000;
  font-size: 22px;
  font-weight: bold;
}

.experience-modal .right {
  padding: 2pc;
}

.experience-gallery .left .image {
  position: relative;
  padding-bottom: 6pc;
}

.experience-gallery .left .img {
  width: 65%;
  object-fit: cover;
  height: 400px;
}

.experience-gallery .left .img:nth-child(2) {
  position: absolute;
  right: 0pc;
  bottom: 0;
  background: #fff;
  padding: 5px;
}

.experience-gallery.reverse .row {
  flex-direction: row-reverse;
}

.experience-gallery-2 .box {
  position: relative;
  transition: all 0.5s;
}

.experience-gallery-2 .box img {
  height: auto;
  border-radius: 15px;
}

.experience-gallery-2 .box:not(:last-child) {
  margin-bottom: 2pc;
}

.experience-gallery-2 .col-12:nth-child(3n + 2) .box {
  margin-top: 3pc;
}

.experience-gallery-2 .content {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #edededde;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3pc;
  text-align: center;
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.experience-gallery-2 .box:hover .content {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}

.experience-gallery-2 .box:hover {
  transform: scale(0.95);
  transition: all 0.5s;
}

.experience-gallery-2 .content p {
  color: #000;
}

.premium-collection ul.tags {
  margin: 0px 0 10px;
  padding: 0;
  gap: 0;
}

.premium-collection ul.tags li:not(:last-child) {
  border-right: 2px solid #88888878;
  padding-right: 10px;
  margin-right: 10px;
}

.premium-collection {
  border: 1px solid #88888829;
}

.premium-collection img {
  border-radius: 10px 10px 0 0;
}

.premium-collection .content {
  padding: 15px 0 0;
}

.premium-collection .content>.d-flex {
  border-top: 1px solid #88888829;
  padding: 14px 1pc;
}

.premium-collection .content>h4,
.premium-collection .content>p,
.premium-collection .content>ul.tags {
  padding: 0 16px;
}

.link_tink.itneryfaqsection .accordion-button {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
}

.link_tink.itneryfaqsection .accordion-body {
  padding: 5px 0px 20px;
}

.link_tink.itneryfaqsection .accordion-body p {
  color: #979797;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.policies_cont li {
  color: #979797;
}

.link_tink.itneryfaqsection .accordion-button:focus {
  box-shadow: none;
}

.card_spotslide img {
  border-radius: 4px;
  height: 300px;
  margin-bottom: 8px;
  object-fit: cover;
  width: 100%;
}

.home-coupons .box {
  background-image: url("../img/coupon.png");
  background-size: 100% 100%;
  padding: 2pc 2pc 1pc;
  position: relative;
  margin-top: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-coupons .swiper-wrapper {
  padding: 0;
}

.home-coupons .box .code {
  padding: 7px 10px;
  width: max-content;
  background: #fff;
  border: 2px dashed var(--brown);
  color: var(--brown);
  font-weight: 600;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.home-coupons .box .code svg {
  font-size: 16px;
}

.home-coupons .box p.off {
  background: var(--brown);
  position: absolute;
  top: -5px;
  right: 2pc;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 600;
  line-height: normal;
  padding: 15px 8px;
  align-items: center;
  border-radius: 0 0 30px 30px;
  margin: 0;
  font-size: 14px;
}

.home-coupons .box p.off span {
  font-size: 18px;
}

.home-coupons .box p.desp {
  color: #000;
  font-size: 15px;
}

.home-coupons .box p.small {
  color: #000;
  font-weight: 500;
  margin: 0;
}

.faq-page .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: var(--brown);
  --bs-accordion-active-color: #000;
  --bs-accordion-btn-focus-border-color: var(--brown);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #9b6a4240;
  --bs-accordion-active-bg: var(--bg);
  --bs-accordion-btn-active-icon: url("../img/faq_remove.png");
  --bs-accordion-btn-icon: url("../img/faq_add.png");
  --bs-accordion-body-padding-y: 10px;
  --bs-accordion-btn-icon-width: 16px;
}

.faq-page .accordion-body {
  font-size: 15px;
  line-height: 25px;
}

.faq-page .accordion-button:focus {
  box-shadow: none;
}

.faq-page .accordion-button:not(.collapsed) {
  border: 0;
  box-shadow: none;
  font-weight: 600;
}

.policy p {
  color: #333;
  line-height: 30px;
}

.policy p span {
  color: var(--brown);
  font-weight: 600;
}

.places-box.style-2 {
  border-radius: 0;
  box-shadow: 7px 8px 20px 0px #d3d3d3bd;
}

.places-box.style-2 ul.tags {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px 1pc;
  width: 100%;
  font-size: 15px;
}

.places-box.style-2 ul.tags li {
  color: inherit;
}

.places-box.style-2 ul.tags li:not(:last-child) {
  border-right: 2px solid #fff;
  padding-right: 10px;
  margin-right: 10px;
}

.places-box.style-2 .btn {
  display: none;
}

.places-box.style-2 img {
  height: 385px;
  border-radius: 0;
}

.places-box.style-2 .content h4 {
  font-weight: 600;
}

.places-box.style-2 .image:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, transparent 75%, #000000a3);
  pointer-events: none;
}

.places-box.style-2 .content>p.small {
  color: #000;
  font-weight: 500;
  margin-top: 3px;
}

.card_facilut {
  align-items: center;
  /* background: rgba(238, 243, 255, 0.6); */
  border-radius: 9px;
  display: flex;
  gap: 4px;
  /* min-width: 159px; */
  /* padding: 0px 0.75rem; */
  margin-right: 5px;
  /* justify-content: center; */
}

.faciluty_section h3 {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.hding_title.faciluty_section p {
  margin-bottom: 21px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.list_emenys ul {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  align-items: center;
}

.list_usermainview_modal li {
  list-style: none;
  color: #6b6969;
  font-weight: 500;
  font-size: 15px;
  border: 1px dashed #b1adad;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: capitalize;
  background-color: #fff;
}

.list_usermainview_modal ul {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.list_ung img {
  width: 18px;
}

.list_modal_view .modal-header {
  padding: 7px 15px;
}

.list_modal_view .modal-body ol {
  padding: 0;
  margin: 0;
  padding-left: 15px;
}

.home-experience img:not(:last-child),
.home-experience video:not(:last-child) {
  margin-bottom: 20px;
}

.home-experience {
  transform: scale(0.9);
}

.accordion.accordion-flush .accordion-button:focus {
  box-shadow: none;
}

.hotelinfocard {
  background: rgb(255, 255, 255);
  /* border: 1px solid rgb(242, 242, 242); */
  box-sizing: border-box;
  /* box-shadow: rgb(221 221 221 / 90%) 0px 0px 50px 4px; */
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
  /* border-radius: 17px; */
  border: 1px solid #fff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.info_hotealcare {
  padding: 19px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info_hotealcare h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  color: #000;
  width: 180px;
}

.info_hotealcare li {
  font-size: 17px;
  font-weight: 600;
  color: #000000;
  margin: 0px 0;
}

.info_hotealcare .btn-host {
  background-color: #fff;
  padding: 9px 21px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 4px;
  right: 4px;
  border: 1px solid #ddbe70;
  border-top-right-radius: 17px;
  border-bottom-left-radius: 17px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.hotelinfocard.active {
  border-color: #ddbe70;
}

.btn-host.removebtn {
  background-color: #ddbe70;
  color: #ffffff;
}

.room_detailsmove .swiper-slide {
  margin: 45px 0;
}

.justifyspny {
  justify-content: space-between;
}

span.textsbig {
  font-size: 12px;
  font-weight: 600;
  color: #534b4b;
}

.textsmall {
  font-size: 12px;
  font-weight: 600;
  color: #666;
}

.price_room p {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  color: #666;
}

.totaltext {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 600;
  color: #666;
}

/*-------------------------------------- checkout css--------------- */
.checktoupagemain .container-fluid {
  padding: 0px 14pc;
}

.content_cartcekcgout {
  background: #ffffff;
  border-radius: 8px;
  padding: 5px 15px;
}

.boxcehckout {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.content_cartcekcgout h6{
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}
.content_cartcekcgout h2 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 28px;
  padding-bottom: 10px;
  color: #000;
}

.content_cartcekcgout svg {
  color: #00d1ff;
}

.content_cartcekcgout p {
  color: #717171;
  font-size: 14px;
  font-weight: 600;
}


.imgchedkoutleft img {
  height: 270px;
  object-fit: cover;
  border-radius: 10px;
}

.detailschekinout {
  /* background: rgba(242, 242, 242, 0.4); */
  margin: 10px 0px;
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-between;
  z-index: 3;
  position: relative;
}

.detailschekinout::after{
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  background-color: #000;
  width: 2px;
}

.detailschekinout p {
  color: #000;
  font-size: 13px;
  margin-bottom: 0;
}
.checkinsection{
  position: relative;
}



.detailschekinout h5 {
  font-size: 19px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
  padding: 5px 0;
}

.checkinmidelsection p {
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 39px;
  position: relative;
  padding: 4px 14px;
  color: #01a3c7;
}

.checkinmidelsection p::after {
  content: "";
  position: absolute;
  top: 14px;
  right: -33px;
  border: 1px dashed #afafaf;
  width: 150px;
  z-index: -1;
}

.listnighchekout {
  /* background: rgba(242, 242, 242, 0.4); */
  padding: 5px 0px 0px;
  /* margin-top: 20px; */
}

.listnighchekout ul {
  display: flex;
  gap: 10px;
}

.listnighchekout ul li {
  color: #000;
  font-size: 18px;
}

.shadwocheckout {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.roomtwinsection {
  padding: 12px 15px;
}

.txtend {
  margin-bottom: 0;
  color: #00b2ff;
  font-size: 14px;
  font-weight: 500;
}

.roomtwinsection h2 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

.adtule {
  font-size: 14px;
  color: #9b9b9b;
  font-weight: 400;
}

.inforoome li {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #000;
}

.inforoome li svg {
  color: #117c00;
  font-size: 20px;
}

.inforoome li span {
  color: #0084ff;
}

.refundlistinfo ul {
  margin-top: 15px;
  display: flex;
  gap: 30px;
  z-index: 3;
  position: relative;
}

.lineuder {
  color: #00ae31 !important;
  position: relative;
  background-color: #ffff;
}

.lineuder::after {
  content: "";
  position: absolute;
  top: 11px;
  right: -22px;
  border: 1px dotted #00b2ff;
  width: 126px;
  z-index: -1;
  left: -16px;
}

.hotal_card .card-header {
  background: transparent;
  /* border-radius: 8px 8px 0px 0px; */
  border: none;
  padding: 20px 0;
  color: #000;
  font-size: 1.3rem;
}

.hotal_card .card-header h5 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.hotal_card .card {
  border: none;
}

.hotal_card .card-body {
  background: transparent;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 30px;
}

.btn.Longi_btn{
  background-color: #000;
  padding: 10px 24px;
  color: #fff;
  border-radius: 3px;
}
.Longi_btn.btn-sm{
  padding: 5px 10px !important;
  font-size: 10px !important; 
}
.card-header.rightheading{
  font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 10px;
    /* margin-bottom: 10px; */
    
    color: #000;
}
.hotal_card .card-body ul {
  padding-left: 20px;
}

.hotal_card .card-body li {
  list-style: disc;
  color: #000;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.hotal_card .input-group {
  border: 1px solid #727272;
  border-radius: 4px;
}

.hotal_card .form-control {
  background: #ffffff;
  flex: 8;
  margin-left: 1px !important;
  box-shadow: none;
  border-color: #d9d9d9;
}
.row.label_text .form-control, .row.label_text .input-group .form-control {
  border-color: #d9d9d9 !important;
}

.hotal_card .form-select:focus {
  box-shadow: none;
}

.hotal_card .form-select {
  border-right: 1px solid #727272;
  z-index: 1;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.leftrromdtails h4,
.leftrromdtails h5,
.leftrromdtails h6 {
  margin-bottom: 10px;
}

.leftrromdtails h4 {
  font-size: 20px;
  font-weight: 600;
}

.leftrromdtails h5 {
  font-size: 17px;
  font-weight: 500;
  color: #000 !important;
}

.leftrromdtails h6 {
  color: #000 !important;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.pricerrom p {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.coupondode {
  background: #ffffff;
  /* box-shadow: 0px 4px 10px rgb(0 0 0 / 10%); */
  border-radius: 8px;
  padding: 15px 0px;
}

.reviwheading h2 {
  font-weight: 600;
  font-size: 24px;
  color: #000;
  margin-bottom: 10px;
}

.reviwheading p {
  font-size: 20px;
  font-weight: 400;
  color: #000;
}

.mb-20 {
  margin-bottom: 20px;
}

.hotal_card h5 {
  color: #fff;
}

.hotalrules h6 {
  font-size: 18px;
  font-weight: 600;
}

.label_text label {
  font-size: 16px;
  color: #000;
  margin-bottom: 3px;
  font-weight: 500;
}

.discount-a p {
  margin-bottom: 0;
  font-weight: 600;
  color: #000;
}

.discountext {
  color: #7d7d7d !important;
}

.gren_price {
  color: #009f2d;
}

.texttex {
  font-weight: 400 !important;
}

.totalnt h6 {
  font-weight: 700;
}

.btn_pay .btn-pay {
  background-color: #ddbe70;
  border-radius: 4px;
  padding: 8px 47px;
  color: #fff;
}

.info .btn-pay{
  width: 100%;
}
.coupondode h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.inpoutborder {
  border: 0.5px solid #6d6d6d;
  border-radius: 8px;
  padding: 15px 15px;
}

.inpoutborder h5 {
  font-size: 16px;
  font-weight: 600;
}

.inpoutborder p {
  font-size: 16px;
  color: #4a4a4a;
  margin-right: 15px;
  margin-bottom: 10px;
}

.inpoutborder h6 {
  font-size: 16px;
  font-weight: 500;
}

.giftxardbrd {
  margin: 20px 0;
  background-color: #ffedd1;
  text-align: center;
  padding: 10px 10px;
  border-radius: 5px;
  position: relative;
}

.giftxardbrd::after {
  content: "";
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  display: block;
  top: 12px;
}

.giftxardbrd::before {
  content: "";
  position: absolute;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  display: block;
  top: 12px;
}

.giftxardbrd p {
  margin-bottom: 0;
  color: #000;
  font-weight: 400;
}

.couponentercode {
  position: relative;
}

.couponentercode .form-control {
  padding-right: 80px;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  border-radius: 4px !important;
}

.couponentercode svg {
  position: absolute;
  top: 13px;
  font-size: 23px;
  right: 15px;
  color: #000;
  cursor: pointer;
  z-index: 99999999999999999999;
}

.list-check .card-header {
  font-size: 20px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}
.list-check ul {
  padding-left: 0px !important;
  padding: 0;
  margin: 0;
}

.list-check li {
  font-size: 15px !important;
  margin-bottom: 10px !important;
  list-style: none !important;
}

.list-check li svg {
  color: #ddbe76;
  font-size: 20px;
}

.testimonial-page .box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-page .col-12:nth-child(3n + 2) .box {
  margin-top: 3pc;
}

ul.blog-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

ul.blog-pagination li.selected a,
ul.blog-pagination li.previous a,
ul.blog-pagination li.next a {
  background: var(--brown);
  color: #fff !important;
}

ul.blog-pagination li.disabled {
  opacity: 0.5;
}

ul.blog-pagination li:hover a {
  background: var(--brown);
  transition: all 0.5s;
  color: #fff !important;
  transition: all 0.5s;
}

ul.blog-pagination li a {
  color: var(--brown) !important;
  text-transform: capitalize;
  background: #fff;
  box-shadow: 0 0 15px #00000020;
  padding: 10px 25px;
  border-radius: 7px;
  border: 2px solid var(--brown);
  font-weight: 600;
}

.sticky-top {
  top: 1pc;
}

.user-dashboard .left>ul,
.user-dashboard .right .box {
  box-shadow: 0px 0px 16px #0000000f;
  border-radius: 10px;
  padding: 2pc 2pc;
  border: 1px solid #ddd;
  background: #fff;
}

.user-dashboard .left ul li.name-box {
  width: 140px;
  height: 140px;
  background-image: linear-gradient(45deg, #ffc107, var(--brown));
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 6px;
  margin: 0 auto 2pc;
}

.user-dashboard .left ul li.item {
  display: flex;
  align-items: center;
  gap: 1pc;
  padding: 10px 10px;
  border-radius: 5px;
  font-weight: 600;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 15px;
}

.user-dashboard .left ul li.active {
  background: #fff7e6;
  color: var(--brown);
}

.user-dashboard .right .info {
  background-color: #eff3f7;
  border-radius: 15px;
  padding: 2pc;
  row-gap: 1pc;
}

.dashboard-booking {
  background-color: #eff3f7;
  border-radius: 15px;
  box-shadow: none;
}

.dashboard-booking .img-fluid {
  height: 290px;
  width: 100%;
  object-fit: cover;
  border-radius: 15px 0 0 15px;
}

.user-dashboard .right .content_cartcekcgout {
  background-color: #eff3f7;
  padding: 1pc;
}

.dashboard-booking p.small {
  font-size: 13px !important;
}

.dashboard-booking .listnighchekout li {
  list-style-type: circle;
  list-style-position: inside;
  font-size: 16px;
}

.review-modal .form-control,
.user-dashboard .form-control {
  background-color: #fafafc;
  border: 1px solid #f6f4ff;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
}

.dashboard-profile {
  border-bottom: 1px solid #ddd;
  padding: 1pc 0;
}

.booking-primary-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1pc;
  flex-wrap: wrap;
}

.booking-primary-contact li {
  color: #000;
  font-weight: 500;
}

.booking-page .user_inof {
  background-color: #fffaf1 !important;
  box-shadow: none !important;
  border-radius: 10px;
  padding: 2pc 2pc;
  border: 1px solid var(--brown);
}

.booking-page .price-breakup li {
  border-bottom: 1px solid #ddd;
  gap: 10px;
  padding: 10px 0;
}

.booking-page .price-breakup li:last-child {
  border: 0;
}

.booking-page {
  position: relative;
  overflow: hidden;
}

.booking-page:before {
  content: "";
  position: absolute;
  left: 0;
  top: -300px;
  height: 600px;
  width: 100%;
  background: #fff8edad;
  border-radius: 20%;
  z-index: 0;
}

.booking-page .container {
  position: relative;
  z-index: 14;
}

.intinery-bottom {
  border-top: 1px solid #ddd;
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #fff;
  z-index: 9999;
}

.availability-modal .user_inof input {
  background-color: #fafafc;
  border: 1px solid #f6f4ff;
  font-weight: 500;
  color: var(--brown);
  letter-spacing: 2px;
  width: 100%;
  padding: 10px 1pc;
  border-radius: 4px;
}

.availability-modal .user_inof input:focus-visible {
  background-color: #fff;
  outline: none;
}

.dateyear {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.dateyear p {
  margin-bottom: 0;
}

/* .weekdays ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.weekdays .col {
  display: inline-block;
  width: 14.2%;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.monthdate .col {
  display: inline-block;
  width: 14.2%;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 500;
  position: relative;
  margin-bottom: 15px;

}

.monthdate .notavailable:after {
  position: absolute;
  top: -15px;
  bottom: 0;
  left: 0;
  right: 0;
  content: "\274c";
  /* use the hex value here... */
  font-size: 25px;
  color: #FFF;
  line-height: 100px;
  text-align: center;
}

.monthdate .disabled {
  color: #7f8988;
}

.monthdate .selected {
  background-color: #ddbe70;
  color: #fff;

}

.monthdate .col .Price {
  font-size: 9px;
  margin-bottom: 0;
  position: absolute;
  /* left: 10px; */
  /* bottom: -11px; */
}

.mainvideoheaer {
  height: 530px;
}


.our-collection .swiper-button-next:after,
.our-collection .swiper-button-prev:after {
  background-color: var(--brown);
  border-radius: 60px;
  color: #000;
  font-size: 18px;
  line-height: 1;
  padding: 11px 15px;
  position: relative;
  text-align: center;
  z-index: 9;
}

.conten_decipt p {
  color: #979797;
}

/* .mobilephoto::after{
  content: '';
  width: 100%;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.4);
}
.mobilephoto{
position: relative;
} */
.mobilephoto {
  height: 300px;
  width: 100%;
  object-fit: fill;
  border-radius: 0px;
  position: relative;
}

.home_slide::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 28%);
}

.home_slide {
  position: relative;
}

.itinery_box .swiper-pagination.swiper-pagination-clickable .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background-color: #fff !important;
}

.mobile_seciont {
  position: relative;
}

.showmoreimg {
  position: absolute;
  bottom: 15%;
  z-index: 888;
  right: 12px;
}

.showmoreimg img {
  object-fit: cover;
  width: 146px;
  height: 76px;
  border: 4px solid #fff;
  text-align: center;
  position: relative;
}

.backimg {
  position: relative;
}

.backimg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 28%);
}


.showmoreimg h3 {
  position: absolute;
  top: 50%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 12px;
  color: #fff;
  text-align: center;

}

.home-benefits .box {
  min-height: 390px;
}

p.linkreadmore {
  cursor: pointer;
  color: #000;
  font-weight: 600;
}

.flexmobileclass {
  color: #979797;
  font-size: 16px !important;
  line-height: 30px !important;
}

.mobile_responsive {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
  /* padding: 10px 0; */
  text-align: center;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: 0 -3px 8px rgb(0 0 0 / 6%);
}

.mobile_responsive p {
  margin-bottom: 0;
}

.border-right {
  border-right: 1px solid #ccc;
  padding: 10px 0;
}

.padding10 {
  padding: 10px 0;
}

.hotalfiltermobile .modal-content {
  height: 100vh;
  overflow-y: auto;
}

.hotalfiltermobile .modal-dialog {
  margin: 0;
}

.hotalfiltermobile .modal-body {
  padding: 20px 15px 78px;
}

.bntfixed {
  position: fixed;
  bottom: 0px;
  width: 90%;
  padding: 8px 0;
  background-color: #fff;
  /* box-shadow: 0 4px 12px 4px #bdbdbd; */
}

a.btn.btnfiltertab {
  background-color: #ddbe70;
  padding: 8px 27px;
}

.bntfixed a {
  color: #000;
  font-size: 15px;
  text-decoration: underline !important;
}

.sortmodal h3 {
  text-align: center;
  margin-bottom: 0;
  font-size: 19px;
  font-weight: 600;
}

.radiobtn-input label {
  font-weight: 400 !important;
  font-size: 16px;
  color: #222222;
}

.pe2 {
  margin-right: 9px;
}

.borderbotmulclass {
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px;
}

.borderbotmulclass input[type="radio"]:checked {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

/* .pe2.radbtn{
  accent-color: #ddbe70;
} */
.borderbotmulclass li {
  margin-bottom: px;
}

.location-browsing .swiper-button-next:after,
.location-browsing .swiper-button-prev:after {
  background-color: var(--brown);
  border-radius: 60px;
  color: #000;
  font-size: 18px;
  line-height: 1;
  padding: 11px 15px;
  position: relative;
  text-align: center;
  z-index: 9;
  /* opacity: 0; */
  transition: all 1s;
}

.location-browsing .swiper:hover .swiper-button-prev:after {
  opacity: 1;
}

.location-browsing .swiper:hover .swiper-button-next:after {
  opacity: 1;
}

.home-benefits .swiper-button-next:after,
.home-benefits .swiper-button-prev:after {
  background-color: var(--brown);
  border-radius: 60px;
  color: #000;
  font-size: 18px;
  line-height: 1;
  padding: 11px 15px;
  position: relative;
  text-align: center;
  z-index: 9;
  /* opacity: 0; */
  transition: all 1s;
}

.home-testimonial .swiper-button-next:after,
.home-testimonial .swiper-button-prev:after {
  background-color: var(--brown);
  border-radius: 60px;
  color: #000;
  font-size: 18px;
  line-height: 1;
  padding: 11px 15px;
  position: relative;
  text-align: center;
  z-index: 9;
  /* opacity: 0; */
  transition: all 1s;
}

.home-benefits .swiper:hover .swiper-button-next:after {
  opacity: 1;
}

.home-benefits .swiper:hover .swiper-button-prev:after {
  opacity: 1;
}

.home-testimonial .swiper:hover .swiper-button-prev:after {
  opacity: 1;
}

.home-testimonial .swiper:hover .swiper-button-next:after {
  opacity: 1;
}

.numdatecalunder {
  background: transparent;
  font-size: 13px;
}

.filterrightsidemodal {
  padding-bottom: 50px;
}

.show-filter {
  display: block;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999999999;
  transition: all ease-in-out 0.5s;
  overflow-x: scroll;
  padding: 10px 10px 30px;

}

.closebtnname {
  position: absolute;
  right: 8px;
  top: 7px;
  padding: 10px;
}

/* .closebtnname {
  position: absolute;
  right: 10px;
  top: 10px;
} */
.hide-filter {
  display: block;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: -100vw;
  transition: all ease-in-out 0.5s;
}

.btn-doneselece {
  width: 100%;
  margin-top: 15px;
}

.boottomsheet.offcanvas-bottom {
  height: 90%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: 20px;
  z-index: 99999999999;
}

.attractions.boottomsheet.offcanvas-bottom {
  height: 40%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: 20px;
  z-index: 99999999999;
}

.newpagebootomsheet.offcanvas-bottom {
  height: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding-bottom: 20px;
  z-index: 99999999999;
}

.newpagebootomsheet .btn-close {
  background-image: url(../img/arrow-left-iocn.png) !important;
  padding: 0;
}

.newpagebootomsheet .offcanvas-header {
  flex-direction: row-reverse;
  box-shadow: rgb(0 0 0 / 7%) 0px 2px 5px -1px, rgb(0 0 0 / 0%) 0px 1px 3px -1px;
  padding: 11px 13px;
}



.attractions.boottomsheet.offcanvas-bottom .user_inof {
  padding: 15px;
  border-radius: 10px;
}

.boottomsheetsortyby.offcanvas-bottom {
  height: 30%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* padding-bottom: 120px; */
}

.user_inof h4 {

  padding-bottom: 10px;
  margin-bottom: 20px;
}

.moblie_itner_modaldate h3 {
  margin-bottom: 18px;
  font-weight: 400;
}

.user_inof .flex_mobileclass h4 {
  border-bottom: 1px solid #ccc;
  font-weight: 600;
  font-size: 22px;
}

.moblie_itner_modaldate .form-control {
  padding: 18px 20px !important;
  box-shadow: none !important;
  border-radius: 8px;
  border: 1px solid #e1e1e1 !important;
}

.zoom_effect {
  overflow: hidden;
}

.zoom_effect img {
  transition: all .5s;
}

.zoom_effect:hover img {
  object-position: right;
  -webkit-transform: scale(1.2);
  transform: scale(1.2) rotate(4deg);
  transition: all .5s;
}

.slidermodalimageone .swiper-button-prev:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;

}

.slidermodalimageone .swiper-button-next:after {
  text-align: center;
  /* line-height: 60px; */
  border-radius: 60px;
  padding: 11px 15px;
  color: black;
  line-height: 1;
  font-size: 18px;
  background-color: var(--brown);
  position: relative;
  z-index: 9;
}

.h-320 {
  height: 214px;
  margin-bottom: 5px;
  object-fit: cover;
}

.cutprice_new {
  font-size: 20px;
  text-decoration: line-through;
  color: #b9b9b9;
  margin-right: 14px;
}

.styles_column__2r45X {
  position: relative;
  overflow: hidden;
}

.styles_column__2r45X img {
  transition: all .5s;
}

.styles_column__2r45X:hover img {
  object-position: right;
  -webkit-transform: scale(1.2);
  transform: scale(1.2) rotate(4deg);
  transition: all .5s;
}

.popmodel_o1penlider img {
  height: 60vh;
  width: 100%;
}

.btn_pay .title {
  margin-bottom: 10px;
}

.btn_pay ul.info {
  margin-bottom: 2pc;
  padding: 0;
}

.btn_pay ul.info li {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border: 1px solid #ced4daa3;
  padding: 10px 1pc;
  margin: 10px 0px;
  font-size: 15px;

}

.btn_pay ul.info li .green {
  font-size: 12px;
}

.btn_pay ul.info li span {
  line-height: normal;
}


.checktoupagemain .css-13cymwt-control {
  height: 46px;
}

.info.paymentphonepay {
  padding: 0;
  padding-left: 0 !important;
}

/* .coupon-modal .modal-content{
    background-color: transparent;
    border:none !important;
    padding: 0;
}
.coupon-modal .modal-header .btn-close {
  padding: 0;
  top: 10px;
  position: absolute;
}

.coupon-modal .modal-body{
  padding: 0;
  top: 33px;
  position: absolute;
  right: 17px;
} */




/* ------------------------------------------------------ coupon modal css------------------------------------------------------ */
.couponcard {
  text-align: center;
  position: relative;
  z-index: 1;
}

.couponcard img {
  /* width: 200px; */
  padding-bottom: 30px;

}

.bordertop {
  position: relative;
}

.bordertop::after {
  position: absolute;
  content: '';
  height: 1px;
  background-color: #cccccc;
  width: 80%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  bottom: -34px;
}

/* .couponcard::after{
  position: absolute;
  content: '';
  top: 50px;
  width: 80%;
  height: 1px;
  background-color: red;
} */
.couponcard h3 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 0;
}

.couponcard p {
  color: #000;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0;
}

.couponcard h4 {
  text-align: center;
  font-size: 19px;
  padding: 30px 0px;
  line-height: 26px;
  margin-bottom: 0;
}

.coupon-modal .modal-content {
  width: 590px;
  margin: auto;

}

.btnflex {
  padding: 0px 15px;
  justify-content: space-between;
  gap: 10px;
}

.flex1 {
  flex: 1;
  text-align: center;
}

.btn-bordered {
  border: 2px dotted #999 !important;
  color: #404040 !important;
  border-radius: 15px;
  padding: 9px 16px;
}

.btn-dark {
  background-color: #000000 !important;
  color: #fff !important;
  border-radius: 15px;
  padding: 9px 16px;
}

.thank-you-title {
  font-size: 50px;
  font-family: var(--montserrat);
}

.secanta_snow {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  animation: traveller 30s linear 0s infinite;
}

.secanta_snow img {
  width: 20%;
}

.whatsapp-buttn-box {
  bottom: 20px !important;
  right: 14px;
  left: auto !important;
  cursor: pointer;
  position: fixed;
  z-index: 99999999;
}

#react-paginate {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

#react-paginate ul {
  gap: 20px;
}

#react-paginate li {
  display: inline-block;
  /* border: 1px solid rgb(224, 224, 224);
  color: #000; */
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  color: #fff !important;
}

#react-paginate li a {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #ddbe70 !important;
  background-color: transparent !important;
  border: 1px solid #ddbe70 !important;
}

#react-paginate li a svg {
  font-size: 20px;
}

#react-paginate li.active a {
  background: #ddbe70 !important;
  outline: none;
  color: #fff !important;
}

@media only screen and (max-width: 991px) {
  #react-paginate {
    margin-bottom: 0px !important;
  }
  #react-paginate ul {
    gap: 12px;
    margin-bottom: 0px;
  }

  #react-paginate li a {
    width: 35px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
}
