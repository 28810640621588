@media only screen and (max-width: 380px) {
  .topheader li a {
    font-size: 11px;
  }
}
@media (max-width: 567px){
  .places-box .content .price h5{
    font-size: 15px;
  }
  .widget-visible{
    margin-bottom: 50px !important;
  }
  .tawk-mobile{
    margin-bottom: 50px !important;
  }
}
@media (max-width: 360px){
  .places-box .content .price{
    display: unset;
  }
}
@media only screen and (max-width: 768px) {
  .iocnright_left .swiper-button-next:after {
    right: -10px;
  }
  .iocnright_left .swiper-button-prev:after {
    left: -10px;
  }
  .home-testimonial .box .desp p {
    padding: 0px 24px;
  }

  .mobile_chnages h3 {
    margin-bottom: 4px !important;
    font-size: 18px;
  }
  .mobile_chnages .heading_date h3 {
    font-size: 13px;
  }
  .guest-absolute {
    left: -6px;
  }
  .availability-modal .user_inof input {
    font-size: 14px;
    width: 100%;
    padding: 4px 1pc;
  }
  .mobile_chnages hr {
    padding: 0 !important;
    margin: 7px 0;
    color: #ccc;
  }

  .gallery-page .swiper-button-next:after {
    padding: 7px 11px;
    font-size: 15px;
  }

  .gallery-page .swiper-button-prev:after {
    padding: 7px 11px;
    font-size: 15px;
  }

  .itnery_slider .swiper-button-prev:after,
  .itnery_slider .swiper-button-next:after {
    padding: 7px 11px;
    font-size: 15px;
  }
  .page-banner .form-control.with-icon {
    display: none;
  }

  .btn-smallmobile {
    padding: 5px 9px;
    font-size: 11px;
  }

  .tablebotompading tr {
    margin-bottom: 12px;
  }
  .listing-banner h1 {
    font-size: 19px;
    margin-bottom: 1pc;
  }
  .page-banner p {
    color: #fff;
    font-size: 12px;
  }
  .home-blog {
    flex-direction: column !important;
  }
  .monthdate .number {
    font-size: 10px;
  }
  .list-circle {
    margin-top: 10px;
  }
  .login-page .right {
    padding: 2pc 0;
  }
  .login-page .right .inner {
    max-width: 94%;
    margin: 0 auto;
  }

  .login-page .left img {
    height: 45vh;
  }
  .listing-banner.hideps-5 .ps-5 {
    padding-left: 15px !important;
  }
  .page-banner {
    height: 20vh;
  }
  .page-banner.padding40 .ps-5 {
    padding-left: 15px !important;
  }
  .listing-banner.hiddenp p {
    display: none;
  }

  .mobilsmalltext.page-banner h1 {
    font-size: 19px;
  }
  .page-banner h1 {
    font-size: 20px;
  }
  .itinery_box .right .user_inof {
    padding: 1pc 1pc;
  }
  .blog-detail-page {
    width: 94%;
  }
  .blog_content {
    padding: 10px 0;
  }
  .about-company .left img {
    margin-bottom: 25px;
  }
  .about-company .right img {
    max-height: 299px;
  }
  .copy-right .social-links {
    justify-content: center;
  }
  .copy-right .container-fluid,
  .copy-right .container {
    text-align: center;
    display: unset;
  }
  .why-us .box {
    border-right: none;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    margin-bottom: 18px;
  }
  .our-director .left img {
    height: 192px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
  }
  .our-team img {
    height: 300px;
  }
  .mobile_reverse {
    flex-direction: column-reverse;
  }
  .numdatecalunder {
    background: transparent;
    font-size: 6px;
  }
  .monthdate .col {
    margin-bottom: 5px;
    padding-left: 1px;
    padding-right: 1px;
  }

  .right_icon .social-links {
    display: none !important;
  }
  .left_topbar {
    text-align: center;
    margin-bottom: 5px;
  }

  .px-4pc {
    padding: 0;
  }

  .topheader ul.social-links {
    justify-content: center;
  }
  nav.navbar_top,
  .topheader {
    padding-left: 1pc;
    padding-right: 1pc;
  }

  .topheader {
    font-size: 13px;
  }

  .slider_caption h2 {
    font-size: 25px;
    display: none;
  }
  .main_logo img {
    width: 7pc;
  }
  .slider_baner_home img {
    height: 30vh;
  }

  .home-banner-tabs .outer-inner {
    flex-direction: column;
    align-items: flex-start;
    gap: 1pc;
  }

  .home-banner-tabs .box li.inner:not(:last-child) {
    border: 0;
  }
  .home-banner-tabs .btn-brown {
    font-size: 13px;
    padding: 6px 1pc;
  }
  .title_header h2 {
    font-size: 28px;
    flex: 6 1;
  }

  .title_header.mb-5 {
    margin-bottom: 2pc !important;
  }
  .padding40 {
    padding-top: 2pc !important;
    padding-bottom: 2pc !important;
  }
  .pt-40 {
    padding-top: 2pc !important;
  }
  .pb-40 {
    padding-bottom: 2pc !important;
  }
  .margin40 {
    margin-top: 2pc !important;
    margin-bottom: 2pc !important;
  }
  .mt-40 {
    margin-top: 2pc !important;
  }
  .mb-40 {
    margin-bottom: 2pc !important;
  }
  .box_location {
    height: 100px;
    width: 100px;
  }

  .box_location .absolute-text p {
    font-size: 12px;
    margin: 8px 0 14px;
  }
  .places-box img {
    height: 160px !important;
    border-radius: 8px 8px 0 0;
  }

  .places-box .content h4 {
    font-size: 16px;
  }

  .places-box .content h4 + p.small {
    font-size: 11px;
  }

  .premium-collection .content > .d-flex {
    flex-direction: column;
    align-items: flex-start !important;
    padding: 6px 10px 10px;
  }

  .btn-brown-outline,
  .btn-brown {
    font-size: 12px;
    border-width: 1px;
  }

  .places-box {
    border-radius: 8px;
    height: 100%;
  }

  .premium-collection .content {
    padding-top: 10px;
  }

  .premium-collection .content > h4,
  .premium-collection .content > p,
  .premium-collection .content > ul.tags {
    padding: 0 10px;
  }
  .filters-2 {
    overflow-x: scroll;
    width: 100%;
  }

  .filters-2::-webkit-scrollbar {
    height: 0;
  }

  .filters-2 li {
    font-weight: 500;
    font-size: 15px;
    padding: 0 10px 4px;
    border-bottom-width: 2px !important;
  }

  .home-benefits p {
    font-size: 12px;
    padding: 0 10px 10px;
  }

  .home-benefits .box .content {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .home-benefits .box {
    min-height: 210px;
    border-image-slice: 17 !important;
  }
  .collection-box {
    height: 250px;
    padding: 1pc;
  }

  .collection-box h4 {
    font-size: 18px;
  }
  .home-coupons .box {
    padding: 1pc;
  }

  .home-coupons .box p.off {
    padding: 10px 8px;
    right: 10px;
    font-size: 12px;
  }

  .home-coupons .box p.off span {
    font-size: 14px;
  }

  .home-coupons .box .code {
    font-size: 10px;
    border-width: 1px;
    margin-bottom: 10px;
  }

  .home-coupons .box .code svg {
    font-size: 12px;
  }

  .home-coupons .box p.desp {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .home-coupons .box p.small {
    font-size: 11px;
  }
  .home-experience {
    transform: none;
    padding: 30px 0 0;
  }
  .home-experience video {
    height: auto !important;
  }
  .home-testimonial .box .image img {
    height: 90px;
    width: 90px;
  }

  .home-testimonial .box p,
  .home-testimonial .box h6 {
    font-size: 12px !important;
  }

  .home-testimonial .box {
    padding: 1pc 10px;
    min-height: 300px;
  }

  .property-listing .left li {
    font-size: 13px;
  }

  .property-listing .left li span {
    height: 18px;
    width: 18px;
  }
  .property-listing .right img {
    height: 150px;
  }
  .newsletter-2 {
    padding: 30px 5px;
  }

  .newsletter-2 .form-control {
    padding: 7px 10xp;
    font-size: 12px;
  }
  .newsletter-2 h2 {
    font-size: 18px;
  }

  .home-blog .image {
    width: 100% !important;
    height: 160px !important;
  }
  .home-blog .content {
    padding: 10px 0px;
  }

  .home-blog .content h5 {
    font-size: 14px;
  }

  .home-blog .content ul.tags {
    font-size: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .home-blog .content p {
    font-size: 12px;
  }
  .faq-page .accordion-button,
  .faq-page .accordion-body {
    font-size: 14px;
  }

  .faq-page .accordion {
    --bs-accordion-btn-icon-width: 10px;
    --bs-accordion-btn-padding-y: 14px;
    --bs-accordion-btn-padding-x: 10px;
  }
  .copy-right {
    padding: 10px 10px 30px;
  }

  .home-benefits .box .icon img {
    height: 150px;
  }
  .home-benefits .box .img {
    display: none;
  }

  /* ===================ITINERY PAGE START==================================== */
  .itinery_box .container-fluid {
    padding: 0 15px;
  }

  #property-gallery {
    flex-direction: column;
  }

  .property-gallery-left {
    width: 100%;
  }

  .new_propery_gallert .right {
    flex-direction: row;
    width: 100%;
    height: auto;
  }

  .ng-star-inserted {
    height: 160px;
    width: 50%;
  }

  .price_section {
    justify-content: flex-start;
    padding: 0;
  }

  .itinery_box ul.blog-filters {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 !important;
    padding: 1pc 0;
  }

  .itinery_box ul.blog-filters::-webkit-scrollbar {
    height: 0px;
  }

  .newboxshadow {
    padding: 2pc 1pc;
  }

  .Itinerary_slide img {
    height: 180px;
  }

  .Itinerary_slide .content {
    padding: 10px !important;
  }

  .Itinerary_slide .content h5 {
    font-size: 16px;
  }

  .Itinerary_slide .content p {
    font-size: 12px !important;
  }

  .heigit_list li {
    width: 48%;
    font-size: 14px;
    line-height: normal;
  }

  .itiner_body ul.blog-filters li {
    padding: 7px 1pc;
  }

  .card_spotslide img {
    height: 150px;
  }
  .gallery-page img {
    height: 45vh;
    cursor: pointer;
  }
  .hding_title h1 {
    font-size: 25px;
  }

  .hding_title.faciluty_section p {
    font-size: 16px;
  }
  .rdrDateRangePickerWrapper {
    zoom: 0.9;
  }
  /* ===================ITINERY PAGE END==================================== */
  .user-dashboard .right .info {
    padding: 1pc;
  }
  ul.blog-filters li {
    padding: 10px 1pc;
    flex-shrink: 0;
    width: max-content !important;
  }

  ul.blog-filters {
    gap: 13px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 7px 0px;
  }

  .blog_section .row.gy-4.mt-5 {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .home-blog .content h5 {
    font-size: 17px;
  }
  .collection-box {
    height: 350px;
    padding: 1pc;
  }

  .collection-box h4 {
    font-size: 20px;
  }
  .itinery_box .container-fluid {
    padding: 0px 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .navbar_top .navbar-nav {
    gap: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .home-blog {
    flex-direction: column !important;
  }
  .location-browsing {
    background-color: #fff !important;
    padding-bottom: 0 !important;
  }
  .home-banner-tabs {
    margin: 0;
    width: 100%;
    padding: 2pc;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1350px) {
  .dawon_arrow {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .flex_mobileclass {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .flex_mobileclass h4,
  .flex_mobileclass h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .user_inof h4 {
    border-bottom: none !important;
  }
  .user_inof .flex_mobileclass h4 {
    font-size: 16px;
  }
  .moblie_itner_modaldate .modal-body {
    padding: 5px;
  }
  .moblie_itner_modaldate .user_inof.date_area h3 {
    padding: 10px 18px;
    margin-bottom: 0 !important;
  }

  .home-testimonial .swiper-button-next:after,
  .home-testimonial .swiper-button-prev:after,
  .location-browsing .swiper-button-next:after,
  .location-browsing .swiper-button-prev:after,
  .home-benefits .swiper-button-next:after,
  .home-benefits .swiper-button-prev:after,
  .our-collection .swiper-button-next:after,
  .our-collection .swiper-button-prev:after {
    font-size: 13px;
    line-height: 1;
    padding: 6px 9px;
  }

  .maintopimg .h-320 {
    height: 245px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .smallhotalimg {
    height: 118px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  /* .blurimg{
    filter: blur(5px);
  } */
  .smallOverlay_text {
    border-radius: 5px;
    align-items: center;
    backdrop-filter: blur(2px);
    background: #0000004d;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 10px 8px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
  .info_hotalmobile {
    background-color: #f6f5f4;
    padding: 10px;
  }
  .info_hotalmobile .content_cartcekcgout {
    background: unset;
    border-radius: 0px;
    padding: 0;
  }
  .info_hotalmobile .content_cartcekcgout h2 {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
  }
  .info_hotalmobile .detailschekinout {
    padding: 0;
    gap: 10px;
  }
  .info_hotalmobile .detailschekinout p {
    font-weight: 700;
    font-size: 9px;
  }
  .info_hotalmobile .detailschekinout h5 {
    color: #ddbe70;
    font-weight: 600;
    font-size: 9px;
  }
  .info_hotalmobile .checkinsection,
  .checkoutsection {
    flex: 1;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    padding: 5px;
  }
  .info_hotalmobile .content_cartcekcgout svg {
    color: #ddbe70;
  }
  .standeredroom,
  .borderratingsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .standeredroom .combo__featureList {
    padding-left: 15px;
  }
  .standeredroom .combo__featureList li {
    border: none;
    font-size: 13px;
    list-style: disc;
    color: #171717;
  }
  .standeredroom .smallhotalimg {
    height: 70px;
    width: 80px !important;
    object-fit: cover;
  }
  .borderratingsection {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 11px;
  }
  .borderratingsection p {
    margin-bottom: 0;
    font-size: 11px;
    color: #000;
    font-weight: 600;
  }
  .hotalimagesbody.offcanvas-body {
    padding: 0;
  }

  .hotal_gallery {
    will-change: transform;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
  }
  .hotal_gallery {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    border-radius: 0;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    position: relative;
  }
  .hotal_gallery img:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transform: scale(1.2) rotate(4deg);
  }

  .blackText {
    color: #000;
    font-weight: 600;
    font-size: 17px;
  }
  .pricing__list {
    color: #9b9b9b;
    display: inline-block;
    font-size: 11px;
    padding-left: 8px;
    position: relative;
    vertical-align: middle;
  }
  .pricing__list:before {
    border-bottom: 1px solid #eb2026;
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    transform: rotate(-6deg);
    width: 90%;
  }
  .combo__footer p {
    font-size: 11px;
    line-height: 1;
    padding-bottom: 4px;
  }
  .bokingareabtn .btn-brown {
    padding: 10px 24px;
  }

  .form-check label {
    align-items: unset;
  }
  .form-check-input {
    margin-top: 5px;
  }

  .inlineblocknonemobile .form-check-inline {
    display: unset;
  }
  .inlineblocknonemobile .form-check-input {
    margin-left: 0;
  }

  .property-listing-steps .bottom-btns .btn {
    padding: 3px 14px;
    font-size: 15px;
    border-radius: 0 !important;
  }
  .page-banner.padding40.listing-banner .container.ps-5 {
    padding-left: 15px !important;
  }

  .property-listing-steps .amenities .form-check {
    margin-bottom: 1pc;
    width: 50%;
  }
  .property-listing-steps .form-check-inline {
    margin-right: 0;
  }
  .property-listing-steps .form-check {
    padding-left: 0;
  }
  .property-listing-steps .inner {
    padding: 0px 15px;
  }
  .title_header .brown.fw-semibold {
    font-size: 12px;
  }
  .mobile_blog_width .col-6 {
    width: 80%;
  }
  .home-banner-tabs {
    width: auto;
    margin: 0;
    position: relative;
    z-index: 4;
    padding: 20px 11px 30px;
  }
  .home-banner-tabs h2 {
    text-align: center;
  }
  .linkreadmore {
    color: #000;
    font-size: 13px;
    font-weight: 600;
    /* text-align: left; */
  }

  .searcinputmobile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 40px;
    transition: width 0.6s linear;
    overflow: hidden;
    will-change: transform;
    padding: 14px 30px;
    margin: 0 auto;
    width: 100%;
    background-color: #f1f1f1;
    border: 1px solid #c4c4c4;
    color: #1e1e1e;
  }
  .searcinputmobile p {
    margin-bottom: 0;
    margin-left: 10px;
    color: #000;
  }
  .mobilepadnig {
    padding: 20px 0;
  }

  .navbar_top_offcanvas a.nav-link {
    border-bottom: 1px solid #ddd;
    padding: 10px 1pc;
  }

  .navbar_top_offcanvas .nav-link {
    border-bottom: 1px solid #ddd;
    padding: 10px 1pc;
  }

  .places-box ul.tags {
    display: none;
  }
  .mainvideoheaer {
    height: 320px;
  }
  .property-listing .left {
    padding: 1pc;
  }
  .property-listing .left ul {
    row-gap: 5px;
  }
  .property-listing p {
    font-size: 13px !important;
    line-height: 24px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .checktoupagemain .container-fluid{
    padding: 0px 4pc !important;
  }
  .detailschekinout{
    width: 80%;
  }

  .hotel-listing .places-box .content .d-flex{
    display: unset !important;
  }
  .itinery_box .container-fluid {
    padding: 0px 4pc;
  }
  .heigit_list li {
    width: calc(50% - 15px);
  }
  .rdrDateRangePickerWrapper {
    width: 100%;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 100%;
  }
  ._box_header_itners {
    display: unset;
    margin-bottom: 10px;
  }
  ._box_header_itners h4 {
    margin-bottom: 12px;
  }
  .box_right_itners p {
    color: #000000b3;
  }
}

@media only screen and (max-width: 1200px) {
  .property-listing:before {
    display: none;
  }
  .banner-tabs .outer-inner {
    display: unset;
  }
  .mobilebottom {
    margin-bottom: 20px;
  }
  ul.blog-pagination {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 8px;
  }
  ul.blog-pagination li a {
    padding: 3px 5px;
    border-radius: 3px;
    border: 1px solid var(--brown);
    font-weight: 600;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1400px) {
  .price_section h4 {
    font-size: 28px;
  }

  .home-blog .image {
    width: 100%;
  }

  .home-blog .content {
    padding: 10px 12px;
  }
  .row-overflow {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .row-overflow::-webkit-scrollbar {
    height: 5px;
  }
  .row-overflow::-webkit-scrollbar-track {
    background: #ddbe7049;
    border-radius: 10px;
  }
  .row-overflow::-webkit-scrollbar-thumb {
    background: #ddbe709e;
    border-radius: 10px;
  }
}
@media (max-width: 1600px) {
  .popmodel_o1penlider img {
    height: 80vh;
    width: 100%;
  }

  .itinery_box .right .user_inof input {
    font-size: 13px;
  }
  .price_section h4 {
    font-size: 28px;
  }
  .home-blog {
    flex-direction: column;
  }
}
@media (max-width: 390px) {
  .insta-pic:before {
    height: 84px;
    background-color: #fff;
  }
  .searcinputmobile p {
    font-size: 13px;
  }
}

/* responsive eheckout pages 03/06/2023  */
@media (max-width: 992px) {
  .checktoupagemain .container-fluid {
    padding: 0px 15px;
  }
  .detailschekinout::after{
    content: unset;
  }
  .detailschekinout{
    
    padding: 10px 0px;
    gap: 15px;
    width: 100%;
  }
  .checkinmidelsection p::after{
    position: unset;
    border: unset;
  }
  .checkinmidelsection p{
    padding: 10px 14px;
    font-size: 19px;
    border-radius: 5px;
  }
  .checktoupagemain  .checkoutsection{
    border-radius: 5px;
    padding: 10px 14px;
    border: none !important;
  }
  .content_cartcekcgout{
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1350px) {
  .detailschekinout{
   display: unset;
  }
  .checkinmidelsection p::after{
    position: unset;
    border: none;
  }
}
